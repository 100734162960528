import { Link } from 'react-router-dom';
import { useState } from 'react';
import ShareBriefModal from '../Popups/ShareBriefModal';
import { Highlight} from 'react-instantsearch';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import CountryBadge from '../badges/CountryBadge';
import Tag from '../tags/Tag';
import ProfilePreview from '../../Pages/MasherProfileView';

export default function MashCard({ hit,currentid, setcurrentid }) {
  const {skills} = hit;
  const isNewMasher = moment(hit.createdAt) > new Date(new Date().setDate(- 30));
  const [openBriefModal, setOpenBriefModal] = useState(false);
  const handleModal = () => {
    setOpenBriefModal(!openBriefModal);
  };

  // const getRoleLabels = (roles) => {
  //   let roleLabelsArray = [];
  //   if (roles) {
  //     roles?.map(role => {
  //       roleLabelsArray.push(role.label);
  //     });
  //   } else {
  //     roleLabelsArray.push('No roles selected by Masher');
  //   }
  //   return roleLabelsArray.join(' / ');
  // };
  const getRoleLabels = (roles) => {
    let roleLabelsSet = new Set();
    if (roles) {
      roles.forEach(role => {
        roleLabelsSet.add(role.label);
      });
    } else {
      roleLabelsSet.add('No roles selected by Masher');
    }
    return Array.from(roleLabelsSet).join(' / ');
  };

  return (
    <div className='search-card-list-items'>
      <div className="search-card-user-container">
        <div className="search-card-user-header">
          <button 
            // data-bs-toggle="modal"
            // data-bs-target="#profilePreview"
            // id="profilePreview_open"
            // onClick={()=>{setcurrentid(hit._id);}}
          >
            <div className="search-card-image-container">
              <p
                style={{backgroundImage: `url(${hit.Photo || hit.Photo || ProfileDefault})`, backgroundSize: 'cover'}}
                className="search-card-image"
              ></p>
            </div>
          </button>
        </div>
        <div className="search-card-masher-details">
          <div className="search-card-masher-line-one">
            <span>
              <button>
                  {hit.Name}
              </button>
            </span>
          </div>
          <div className="search-card-masher-line-one">
            <span>
            {hit.HandleID}
            </span>
          </div>
          <div className="flex-cotainer-insta">
                    <div className="flex-inshits">
                         <div className="flex-insight-num">{hit.posts}</div>
                         <div className="flex-insight-2ndtxt">Posts</div>
                    </div>
                    <div className="flex-inshits">
                         <div className="flex-insight-num">{hit.Followers}</div>
                         <div className="flex-insight-2ndtxt">followers</div>
                    </div>
                    <div className="flex-inshits">
                         <div className="flex-insight-num">{hit.Following}</div>
                         <div className="flex-insight-2ndtxt">following</div>
                    </div>
          </div>
         
        </div>
       
      </div>

      <div className="search-card-right" >
        {openBriefModal && <ShareBriefModal masher={hit} handleModal={handleModal} />}
      </div>
    </div>
  );
}