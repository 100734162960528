import React, { useCallback, useEffect, useReducer } from 'react';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  DoctorRegistrationStepFour,
  DoctorRegistrationStepOne,
  DoctorRegistrationStepThree,
  DoctorRegistrationStepTwo,
} from '../../components/Validation/index.js';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader.js';
import { useState } from 'react';
import { LuUpload } from 'react-icons/lu';
import { PiCertificateFill } from 'react-icons/pi';
import { BsFileMedicalFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi2';
import { FaFileAlt } from 'react-icons/fa';
import { XCircleFill } from 'react-bootstrap-icons';
import { FileprofileComponent } from '../../components/upload/FileUploadComponent.js';

import { useDropzone } from 'react-dropzone';
import { FaAddressCard } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { fileUploadApi } from '../../apis/index.js';
function RegisterFour({ formik, loader }) {
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [profileuploadPercentage, setprofileUploadPercentage] = useState(0);
  const [profilename, setprofileName] = useState('');
  const [profilesize, setprofilesize] = useState('');
  const [profileiserror, setprofileIsError] = useState(false);
  const [isprofile, setisprofile] = useState(false);
  const [filename, setFileName] = useState('');
  const [filesize, setFileSize] = useState('');
  const [fileiserror, setFileIsError] = useState(false);
  const [isfile, setIsFile] = useState(false);

  const onDropProfile = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await profilefileUpload(file); // Ensure this function is defined to handle the upload
      // Here you might want to update Formik's state or component state as necessary
    }
  }, []); // Removed formik from dependency array unless directly used

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
    isDragActive: isDragActiveProfile,
  } = useDropzone({
    onDrop: onDropProfile,
    // add `accept` prop if you want to restrict file types
  });

  const onDropCover = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await coverfileUpload(file); // Ensure this function is defined to handle the upload
      // Update component state as necessary
    }
  }, []); // Removed formik from dependency array unless directly used

  const {
    getRootProps: getRootPropsCover,
    getInputProps: getInputPropsCover,
    isDragActive: isDragActiveCover,
  } = useDropzone({
    onDrop: onDropCover,
    // add `accept` prop if you want to restrict file types
  });
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }

  const onUploadProgress = (progressEvent, file) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadPercentage(progress);
    setFileName(file.name);
    setFileSize(formatFileSize(file.size));
  };

  const fileUpload = async (file, pathFieldName) => {
    try {
      setIsFile(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', null);

      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // setFileName(file.name);
        // setFileSize(formatFileSize(file.size));
        // setIsFile(false);
        formik.setFieldValue('licencename', file.name);
        formik.setFieldValue('licencesize', file.size);
        // Assuming uploadResponse.data.filePath contains the file path
        if (pathFieldName == 'MedicalLicense') {
          formik.setValues({
            ...formik.values,
            MedicalLicense: uploadResponse.data.filePath,
          });
        } else if (pathFieldName == 'DegreeCertificate') {
          formik.setValues({
            ...formik.values,
            DegreeCertificate: uploadResponse.data.filePath,
          });
        }
      } else {
        setFileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setFileIsError(true);
    } finally {
      setIsFile(false);
    }
  };
  const profileonUploadProgress = (progressEvent, file) => {
    console.log(progressEvent);
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setprofileUploadPercentage(progress);
    setprofileName(file.name);
    setprofilesize(formatFileSize(file.size));
  };

  const profilefileUpload = async (file) => {
    try {
      setisprofile(true);
      const formData = new FormData();
      formData.append('file', file);

      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        profileonUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // Here we update only the profile field
        formik.setFieldValue('profile', uploadResponse.data.filePath);

        setisprofile(false);
      } else {
        setprofileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setprofileIsError(true);
    } finally {
      setisprofile(false);
    }
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await fileUpload(file, event.target.name);
    }
  };
  return (
    <form onSubmit={formik.handleSubmit} className="pb-6">
      <section className="space-y-4 shadow-md rounded-xl p-4 md:shadow-none">
        <h3 className="text-lg capitalize font-medium">Submit Documents</h3>
        <div className="bg-[#E8EAF7] p-3 rounded-[14px] flex gap-2 items-center select-none">
          <div>
            <FaFileAlt className="text-2xl text-accent" />
          </div>
          <p className="text-xs font-normal text-[#696F8C]">
            We are required by law to verify your identity by collecting certain
            documents.{' '}
          </p>
        </div>
        <div className="relative cursor-pointer">
          <div className="flex gap-2 items-center justify-between shadow-md rounded-2xl p-3 bg-white">
            <div className="flex gap-2 items-center p-2 max-w-[75%]">
              <div>
                <PiCertificateFill className="text-2xl text-accent" />
              </div>
              <p className="text-sm font-medium text-coalBlack   ps-2 border-l border-gray-200 truncate whitespace-nowrap">
                {formik.values.MedicalLicense === undefined
                  ? 'Medical License'
                  : formik.values.MedicalLicense}
              </p>
            </div>
            <div className="relative">
              <LuUpload className="text-2xl text-coalBlack" />
            </div>
            <input
              type="file"
              name="MedicalLicense"
              className="fileInput"
              id="MedicalLicense"
              // value={formik.values.MedicalLicense}
              accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFileChange}
            />
          </div>
          <div className="pt-2">
            <InputErrorMessage
              error={
                formik.touched.MedicalLicense && formik.errors.MedicalLicense
              }
            />
          </div>
        </div>
        <div className="relative cursor-pointer">
          <div className="flex gap-2 items-center justify-between shadow-md rounded-2xl p-3 bg-white ">
            <div className="flex gap-2 items-center p-2 max-w-[75%]">
              <div>
                <BsFileMedicalFill className="text-2xl text-accent" />
              </div>
              <p className="text-sm font-medium text-coalBlack   ps-2 border-l border-gray-200 w-full truncate whitespace-nowrap">
                {formik.values.DegreeCertificate === undefined
                  ? ' Degree Certificate'
                  : formik.values.DegreeCertificate}
              </p>
            </div>
            <div className="relative">
              <LuUpload className="text-2xl text-coalBlack" />
            </div>
            <input
              type="file"
              name="DegreeCertificate"
              className="fileInput"
              id="DegreeCertificate"
              accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFileChange}
            />
          </div>
          <div className="mt-2">
            <InputErrorMessage
              error={
                formik.touched.DegreeCertificate &&
                formik.errors.DegreeCertificate
              }
            />
          </div>
        </div>
      </section>

      <section className="space-y-4 shadow-md rounded-xl p-4 md:shadow-none">
        <h3 className="text-lg capitalize font-medium">Reference Contacts</h3>
        <div className="bg-[#E8EAF7] p-3 rounded-[14px] flex gap-2 items-center select-none">
          <div>
            <HiUsers className="text-2xl text-accent" />
          </div>
          <p className="text-xs font-normal text-[#696F8C]">
            We require a reference contact for your account to get accepted
          </p>
        </div>
        <div className="refContact">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Name
          </p>
          <div className="relative">
            <input
              type="text"
              name="refContact"
              className={
                Boolean(formik.touched.refContact) &&
                Boolean(formik.errors.refContact)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="refContact"
              placeholder="Enter  name"
              value={formik.values.refContact}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.refContact && formik.errors.refContact}
            />
          </div>
        </div>
        <div className="refPhoneNo">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Mobile Number
          </p>
          <div className="relative">
            <div className="flex gap-1 items-center">
              <input
                type="text"
                readOnly
                className="form-control cursor-not-allowed max-w-12 p-2 inline-flex justify-center items-center"
                value={'+91'}
              />
              <input
                type="text"
                name="refPhoneNo"
                className={
                  Boolean(formik.touched.refPhoneNo) &&
                  Boolean(formik.errors.refPhoneNo)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="refPhoneNo"
                placeholder="Enter 10 digit mobile No"
                value={formik.values.refPhoneNo}
                onChange={handleChange}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.refPhoneNo && formik.errors.refPhoneNo}
            />
          </div>
        </div>
        <div className="uploadProfilePhoto">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Upload Profile Photo
          </p>

          <>
            <div>
              <div>
                <div className="flex gap-4 input-group">
                  <div className="w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0">
                    {isprofile ? (
                      <FileprofileComponent
                        name={profilename}
                        filesize={profilesize}
                        percentage={profileuploadPercentage}
                      />
                    ) : (
                      <>
                        {formik.values.profile && (
                          <div className="flex flex-col items-center  w-[100%]">
                            {formik.values.profile && (
                              <div className="flex flex-row justify-between  w-[100%] mb-4">
                                <div className="truncate w-[80%]">
                                  {' '}
                                  {profilename}
                                </div>
                                <XCircleFill
                                  className="cursor-pointer"
                                  onClick={() => {
                                    formik.setFieldValue('profile', '');
                                  }}
                                />
                              </div>
                            )}
                            <img src={formik.values.profile} />
                          </div>
                        )}
                        {!formik.values.profile && (
                          <div {...getRootPropsProfile()} className="w-[100%]">
                            <input {...getInputPropsProfile()} />
                            {isDragActiveProfile ? (
                              <div className="flex flex-col items-center text-center">
                                <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                  <i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" />
                                </div>
                                <p className="font-semibold text-[#00A46F] mt-6">
                                  Drop here
                                </p>
                                <p>PNG, JPG or GIF (min. 200px)</p>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center text-center">
                                <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                  <i className="bi bi-cloud-arrow-up text-2xl text-[#1648CE]" />
                                </div>
                                <p className="font-semibold text-[#1648CE] mt-6">
                                  Click to upload
                                  <span className="text-[#667085] font-normal hidden md:block">
                                    order drag and drop
                                  </span>
                                </p>
                                <p>PNG, JPG</p>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <InputErrorMessage
              error={formik.touched.profile && formik.errors.profile}
            />
          </>
        </div>
      </section>
      <div className="submitButton mt-4">
        <button
          className="btn btn_primary_black text-white w-full rounded-xl"
          type="submit"
        >
          {!loader ? (
            'Submit'
          ) : (
            <div className="text-xs">
              <EditProfileLoader />
            </div>
          )}
        </button>
      </div>
    </form>
  );
}

export default RegisterFour;
