import React, { useContext, useState } from 'react';
import { EditProfileLoader } from '../common/loader';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { raiseDispute } from '../../apis';
import toast from 'react-hot-toast';
import { AppContext } from '../../appContext';

function DisputeConsultation({consultationId}) {
  const { userDetails } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const handleDispute = () => {
    setLoader(true);
  };
  const toRaiseDispute = async () => {
    setLoader(true);
    const payload = {
      doctorId: userDetails?.DoctorProfile?._id,
      consultationId: consultationId || null,
    };
    try {
      const res = await raiseDispute(payload);
      if (res.status === 200) {
        setLoader(false);
        console.log(res);
        toast.success(res?.data?.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.error || 'Something went wrong');
    } finally {
      setLoader(false);
    }
  };
  return (
    <div className="baseText bg-white mt-4 lg:p-4 lg:rounded-3xl">
      <SimpleButton
        title={loader ? <EditProfileLoader /> : 'Appeal'}
        customClass={
          'text-red-500  border border-red-500 rounded-lg w-full hover:bg-red-500 hover:text-white'
        }
        onClickEvent={toRaiseDispute}
      />
    </div>
  );
}

export default DisputeConsultation;
