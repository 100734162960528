import React, { useState, useEffect } from 'react';
import { getTransactionHistoryApi } from '../../apis';
import toast from 'react-hot-toast';
import Moment from 'react-moment';
import NOProfileimg from '../../images/hos-noprofile.png';
function TransitionCard({ doctorid }) {
  console.log(doctorid);
  const [data, setdata] = useState(null);
  const [loader, setLoader] = useState(false);
  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getTransactionHistoryApi(doctorid);
      if (res.status === 200) {
        console.log(res.data.TransactionHistory);
        setdata(res.data.TransactionHistory);
        // toast.success(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      //toast.error(message, { id: 'error' });
    }
  };
  useEffect(() => {
    getItem();
  }, [doctorid]);
  return (
    <>
      {data?.length > 0 ? (
        data.map((item) => {
          return (
            <div className="flex items-center gap-2" key={item.id}>
              <div className="inline-flex gap-2 items-center grow">
                {item?.Profile?.[0] ? (
                  <img
                    src={item?.Profile?.[0]}
                    className="bg-gray-200 size-10 basis-10  rounded-full"
                  />
                ) : (
                  <img
                    src={NOProfileimg}
                    className="bg-gray-200  rounded-full"
                  />
                )}

                <div className='grow'>
                  <p className="capitalize baseText line-clamp-1 font-normal text-coalBlack max-w-[85%]">
                    {item?.hospitalName?.[0]}
                  </p>
                  <p className="text-navLink smallText">
                    <Moment format="DD/MM/YYYY" date={item?.TransactionAt} />
                  </p>
                </div>
              </div>
              <p className="capitalize basis-auto baseText !font-medium text-coalBlack ms-auto">
                +{item.DoctorFee}
              </p>
            </div>
          );
        })
      ) : (
        <p>No data available</p>
      )}
    </>
  );
}

export default TransitionCard;
