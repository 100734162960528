import React from 'react';

function ResponsiveTable({ headers, data }) {
  return (
    <>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-x-auto scrollbar block">
        <thead className="smallText text-navLink uppercase ">
          {headers?.map((header, index) => (
            <tr className="bg-gray-50 " key={index}>
              {Object.values(header)?.map((cell, cellIndex) => (
                <th
                  key={cellIndex}
                  scope="col"
                  className="px-6 py-3 font-semibold whitespace-nowrap"
                >
                  {cell}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {data?.length === 0 ? (
            <tr className="h-40">
              <td colSpan={Object.keys(headers[0]).length} className="text-center baseText">
                There were no results.
              </td>
            </tr>
          ) : (
            data?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="bg-white text-coalBlack smallText !font-medium  hover:bg-blue-50/50"
              >
                {Object.values(row)?.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={`px-6 py-4 ${
                      cellIndex == 0 ? 'capitalize' : ''
                    }`}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
}

export default ResponsiveTable;
