import React, { useState } from 'react';
import { IoMdLock } from 'react-icons/io';
import { FiChevronRight } from 'react-icons/fi';
import { MdNotificationAdd } from 'react-icons/md';
import { FaChartPie } from 'react-icons/fa6';
import { HiUsers } from 'react-icons/hi2';
import Toggle from '../../components/common/Toggle/Toggle';
import TopNavigation from './ProfileTopNavigation';
import ProfileTopNavigation from './ProfileTopNavigation';
import { Link, Outlet } from 'react-router-dom';
function ProfileSettings() {
  const accSettingLinks = [
    {
      icon: <IoMdLock className="text-white" />,
      bgCol: 'bg-red-500',
      title: 'Change password',
      linkTo: '/profile/settings/changepassword',
    },
    {
      icon: <FaChartPie className="text-white" />,
      bgCol: 'bg-sky-400',
      title: 'Privacy and Safety',
      linkTo: '',
    },
    {
      icon: <HiUsers className="text-white" />,
      bgCol: 'bg-yellow-500',
      title: 'About Us',
      linkTo: '',
    },
  ];
  let accountSettingsLinkBlock;
  accountSettingsLinkBlock = accSettingLinks?.map((data, i) => {
    return (
      <Link to={data.linkTo}  key={i}>
        <div
          className="flex items-center border-b border-gray-200/75 py-4"
         
        >
          <div className="inline-flex items-center gap-4">
            <div
              className={`inline-flex justify-center items-center size-9  rounded-full ${data.bgCol}`}
            >
              {data.icon}
            </div>
            <p className="text-gray-400 font-light">{data.title}</p>
          </div>
          <div className="ms-auto">
            {' '}
            <FiChevronRight className="text-lg text-coalBlack" />
          </div>
        </div>
      </Link>
    );
  });

  const [textMessageToggle, setTextMessageToggle] = useState(true);
  function handelMessageToggle() {
    setTextMessageToggle(!textMessageToggle);
  }
  const [phoneCallToggle, setPhoneCallToggle] = useState(true);
  function handelPhoneCallToggle() {
    setPhoneCallToggle(!phoneCallToggle);
  }
  return (
    <div className="text-coalBlack">
      <ProfileTopNavigation pageTitle={'settings'} />
      <section className="my-8">
        <p className="font-medium capitalize mb-4">Account settings</p>
        <div className="my-8 max-w-md">{accountSettingsLinkBlock}</div>
        {/* <div className="my-8 max-w-md">
          <p className="font-medium capitalize mb-4 text-gray-500">
            More Options
          </p>
          <div className="flex items-center border-b border-gray-200/75 py-4">
            <p className="text-gray-400 font-light capitalize">text messages</p>
            <div className="ms-auto">
              <Toggle
                onclick={handelMessageToggle}
                status={textMessageToggle}
              />
            </div>
          </div>
          <div className="flex items-center border-b border-gray-200/75 py-4">
            <p className="text-gray-400 font-light capitalize">phone calls</p>
            <div className="ms-auto">
              <Toggle
                onclick={handelPhoneCallToggle}
                status={phoneCallToggle}
              />
            </div>
          </div>
          <div className="flex items-center border-b border-gray-200/75 py-4">
            <p className="text-gray-400 font-light capitalize">Languages</p>
            <div className="ms-auto">
              <span className="text-xs font-light capitalize text-gray-400 pe-1">
                english
              </span>
              <FiChevronRight className="text-lg text-coalBlack inline-block" />
            </div>
          </div>
          <div className="flex items-center border-b border-gray-200/75 py-4">
            <p className="text-gray-400 font-light capitalize">currency</p>
            <div className="ms-auto">
              <span className="text-xs font-light capitalize text-gray-400 pe-1">
                INR
              </span>
              <FiChevronRight className="text-lg text-coalBlack inline-block" />
            </div>
          </div>
          <div className="flex items-center border-b border-gray-200/75 py-4">
            <p className="text-gray-400 font-light capitalize">
              linked account
            </p>
            <div className="ms-auto">
              <span className="text-xs font-light capitalize text-gray-400 pe-1">
                google
              </span>
              <FiChevronRight className="text-lg text-coalBlack inline-block" />
            </div>
          </div>
        </div> */}
      </section>
      <Outlet />
    </div>
  );
}

export default ProfileSettings;
