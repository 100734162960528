import React, { useEffect, useReducer } from 'react';
import AdminLayout from '../../components/admin-layout';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FaStarOfLife } from 'react-icons/fa6';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  DoctorRegistrationStepFour,
  DoctorRegistrationStepOne,
  DoctorRegistrationStepThree,
  DoctorRegistrationStepTwo,
} from '../../components/Validation/index.js';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader.js';
import { useState } from 'react';
import { BiBell } from 'react-icons/bi';
import { FiChevronLeft } from 'react-icons/fi';
import { FaAddressCard } from 'react-icons/fa';
import { LuUpload } from 'react-icons/lu';
import { PiCertificateFill } from 'react-icons/pi';
import { BsFileMedicalFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi2';
import { FaFileAlt } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa6';
import MobileModel from '../../components/Modals/MobileModel.js';
import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import { getProfileById, profileCreate } from '../../apis/index.js';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../../components/UserProfile/UserProfile.js';

function RegisterThree({ formik, loader }) {
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <form onSubmit={formik.handleSubmit} className='pb-6'>
      <section className="space-y-4 shadow-md rounded-xl p-4 md:shadow-none">
        <h3 className="text-lg capitalize font-medium">
                    Hospital Details
        </h3>
        <div className="relative practiceHospital">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
                        Practicing Hospital
          </p>
          <input
            type="text"
            name="practiceHospital"
            className={'form-control transparent-input-border'}
            id="practiceHospital"
            placeholder="Apollo multispecialty hospital"
            value={formik.values.practiceHospital}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={
              formik.touched.practiceHospital &&
                            formik.errors.practiceHospital
            }
          />
        </div>
        <div className="relative HospitalNumber">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
                        Hospital Number
          </p>

          <input
            type="text"
            name="HospitalNumber"
            className="form-control transparent-input-border"
            id="HospitalNumber"
            placeholder="044 24566543"
            value={formik.values.HospitalNumber}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={
              formik.touched.HospitalNumber &&
                            formik.errors.HospitalNumber
            }
          />
        </div>
        <div className="relative HospitalMobileNumber">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
                        Hospital Mobile Number
          </p>

          <input
            type="text"
            name="HospitalMobileNumber"
            className="form-control transparent-input-border"
            id="HospitalMobileNumber"
            placeholder="9685748500"
            value={formik.values.HospitalMobileNumber}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={
              formik.touched.HospitalMobileNumber &&
                            formik.errors.HospitalMobileNumber
            }
          />
        </div>
        <div className="submitButton mt-4">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Next'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </section>
    </form>
  );
}

export default RegisterThree;