import React, { useState, useEffect } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { FaSquareCheck } from 'react-icons/fa6';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { FiChevronLeft } from 'react-icons/fi';
import { IoVideocam } from 'react-icons/io5';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { GrNotes } from 'react-icons/gr';
import { RiChat1Line } from 'react-icons/ri';
import MedicalRecord from '../MedicalRecords/MedicalRecord';
import { Person } from 'react-bootstrap-icons';
import { PiStethoscopeFill } from 'react-icons/pi';
import { ConsultationbyID, updateConsultation } from '../../apis';
import toast from 'react-hot-toast';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import UploadPrescription from './UploadPrescription';
import { CgCalendarDates } from 'react-icons/cg';
import { formatDate } from '../Helperfunction/timeconverter';
import { EditProfileLoader } from '../common/loader';
import HospitalChat from '../oddsChat/HospitalChat';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import MapComponent from '../MapComponent';
import Avatar from '../Avatar/Avatar';
import { CiLocationArrow1 } from 'react-icons/ci';
import DisputeConsultation from './DisputeConsultation';

function ViewDetails({ currentdata, closeFunction }) {
  const [data, setdata] = useState(null);
  const [chatBlock, setChatBlock] = useState(false);

  const [uploadPrescriptionBlock, setUploadPrescriptionBlock] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState('');
  useEffect(() => {
    getLocation(); // Trigger on component mount
  }, []); // The empty array ensures this effect runs only once after the initial rendering

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(success, handleError);
  };

  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });
    setError('');
  };
  function haversineDistance(lat1, lon1, lat2, lon2) {
    const toRadians = (degree) => (degree * Math.PI) / 180;

    const R = 6371; // Earth's radius in kilometers
    const deltaLat = toRadians(lat2 - lat1);
    const deltaLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(deltaLon / 2) *
        Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    const formateddistance = formatDistance(distance);
    return formateddistance;
  }
  const formatDistance = (distance) => {
    if (distance < 1) {
      return `${(distance * 1000).toFixed(0)} meters`;
    } else {
      return `${distance.toFixed(2)} km`;
    }
  };
  const handleError = (error) => {
    switch (error.code) {
    case error.PERMISSION_DENIED:
      console.log('User denied the request for Geolocation.');
      setError('Please enable location services and reload the page.');
      handelLocationPromt();
      break;
    case error.POSITION_UNAVAILABLE:
      setError('Location information is unavailable.');
      break;
    case error.TIMEOUT:
      setError('The request to get user location timed out.');
      break;
    default:
      setError('An unknown error occurred.');
      break;
    }
  };
  const [LocationPromt, setLocationPromt] = useState(false);
  function handelLocationPromt() {
    setLocationPromt(!LocationPromt);
  }
  console.log(currentdata);
  const getconsultaitonbyid = async () => {
    try {
      const res = await ConsultationbyID(currentdata);
      if (res.status === 200) {
        console.log(res.data.message);
        console.log(res.data);
        setdata(res.data?.consultationdata);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
    } finally {
    }
  };
  const [loader, setLoader] = useState(false);
  const handledoctorcompleted = async () => {
    setLoader(true);
    const payload = {
      Id: currentdata,
      doctorCompletedStatus: 'completed',
    };
    try {
      const res = await updateConsultation(payload);
      console.log(res);
      if (res.status === 200) {
        toast.success('Updated successfully');
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error('Some thing went wrong');
    }
  };

  useEffect(() => {
    getconsultaitonbyid();
  }, []);
  const [medicalRecordScreen, setMedicalRecordscreen] = useState(false);
  const handelMedicalRecordScreen = () => {
    setMedicalRecordscreen(!medicalRecordScreen);
  };
  const handlePrescriptionBlock = () => {
    setUploadPrescriptionBlock(!uploadPrescriptionBlock);
  };
  function gettypeText() {
    const text = {
      inperson: 'In-Person',
      Virtual: 'Virtual',
    };
    return text[data?.consType];
  }
  function gettypePriorityText() {
    const text = {
      regular: 'Routine',
      urgent: 'Urgent',
    };
    return text[data?.priority];
  }
  const handelChatBlock = () => {
    setChatBlock(!chatBlock);
  };
  function getTypeIcon(text, type) {
    const icons = {
      inperson: (
        <div
          className={`${
            type === 'lg' ? 'min-w-10 h-10' : ' min-w-8 h-8'
          } rounded-lg bg-blue-100 flex justify-center items-center`}
        >
          <Person
            className={`${
              type === 'lg' ? 'text-2xl' : 'text-lg'
            } text-blue-700`}
          />
        </div>
      ),
      Virtual: (
        <div
          className={`${
            type === 'lg' ? 'min-w-10 h-10' : ' min-w-8 h-8'
          } rounded-lg bg-purple-100 flex justify-center items-center`}
        >
          <IoVideocam
            className={`${
              type === 'lg' ? 'text-2xl' : 'text-lg'
            } text-purple-700`}
          />
        </div>
      ),
      // Add more cases if needed
    };
    return icons[text] || null; // Return null if type doesn't match any case
  }

  return (
    <>
      <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[10000]">
        <div
          className="absolute top-0 left-0 w-full h-full bg-inherit"
          onClick={closeFunction}
        ></div>
        <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto scrollbar w-full max-h-screen lg:max-w-4xl xl:max-w-5xl lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl min-h-screen lg:min-h-min overscroll-contain">
          {/* LG Navigation  */}
          <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
            <p className="text-coalBlack subHeadingText">Consultation View</p>
            <p
              className="text-navLink baseText !font-medium min-w-10 p-2 cursor-pointer"
              onClick={closeFunction}
            >
              Close
            </p>
          </div>
          <main className="bg-white p-4 pt-0 pb-44 space-y-3.5  lg:rounded-2xl lg:p-0 lg:bg-inherit">
            {/* SM Navigation  */}
            <div className=" bg-white flex items-center gap-1 sticky top-0 py-4 z-30 lg:hidden">
              <FiChevronLeft
                className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
                onClick={closeFunction}
              />
              <p className="headingText  text-coalBlack capitalize">
                Consultation
              </p>
              <p
                className={`baseText px-4 py-1.5 rounded-full  text-white ms-auto ${
                  data?.priority === 'urgent' ? 'bg-[#FF4539]' : 'bg-[#7be7b2]'
                }`}
              >
                {gettypePriorityText()}
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2  lg:gap-6 place-content-start">
              <section className="grid grid-cols-1 place-content-start">
                {/* SM profile viewer  start*/}
                <div className="flex mb-4 gap-2 lg:hidden">
                  {data?.hospitalId?.profilePic ? (
                    <div className="size-32 basis-32 rounded-xl bg-gray-200">
                      <img
                        src={data?.hospitalId?.profilePic}
                        className="block object-cover w-full h-full rounded-xl"
                      />
                    </div>
                  ) : (
                    <div className="size-32 basis-32 rounded-xl bg-gray-200 flex justify-center items-center">
                      <p className="subHeadingText capitalize">
                        {data?.hospitalId?.hospitalName}
                      </p>
                    </div>
                  )}
                  <div className="space-y-3 shrink-[100]">
                    <p className="text-accent headingText capitalize line-clamp-2">
                      {data?.hospitalId?.hospitalName}
                    </p>
                    {data?.Consultationstatus === 'accepted' &&
                      data?.status != 'completed' && (
                      <div className="flex items-center gap-4">
                        <div
                          className="flex justify-center items-center bg-lightBlue rounded-full cursor-pointer"
                          onClick={handelChatBlock}
                        >
                          <BsFillChatLeftDotsFill className="text-xl min-w-10 size-10 text-accent p-2" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* SM profile viewer  end*/}
                {/* LG profile viewer  */}
                <div className="profileForLargeDevice rounded-3xl bg-white p-4 space-y-3 mb-4 hidden lg:block">
                  <div>
                    {data?.hospitalId?.profilePic ? (
                      <img
                        className="h-36 rounded-2xl bg-white w-[100%] object-cover aspect-square"
                        src={data?.hospitalId?.profilePic}
                      />
                    ) : (
                      <div
                        className="bg-gray-100 dark:bg-gray-700 h-36 rounded-2xl "
                        role="status"
                      >
                        <p className="subHeadingText text-center capitalize py-6">
                          {' '}
                          {data?.hospitalId?.hospitalName}
                        </p>
                      </div>
                    )}
                    {data?.hospitalId?.profilePic ? (
                      <img
                        className="w-14 h-14 bg-white rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white object-cover"
                        src={data?.hospitalId?.profilePic}
                      />
                    ) : (
                      <div
                        role="status"
                        className="bg-gray-50 dark:bg-gray-700 w-14 h-14  rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white"
                      >
                        <p className="subHeadingText text-center capitalize w-full h-full flex justify-center items-center">
                          {' '}
                          {data?.hospitalId?.hospitalName}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="px-6 space-y-3 !mt-0">
                    <p className="text-coalBlack text-center line-clamp-2 headingText capitalize ">
                      {data?.hospitalId?.hospitalName}
                    </p>
                    <div className="flex items-start gap-2">
                      <FaLocationDot className="text-xl text-accent min-w-4" />
                      <p className="baseText text-navLink">
                        {data?.hospitalId?.address}
                      </p>
                    </div>
                    <div className="flex items-start gap-2">
                      <IoMdCall className="text-xl text-accent min-w-4" />
                      <p className="baseText text-navLink">
                        {data?.hospitalId?.refPhoneNo}
                      </p>
                    </div>
                  </div>
                </div>
                <section className="baseText space-y-6 bg-white lg:p-4 lg:rounded-3xl mt-auto">
                  <div className="space-y-2 requestDescription">
                    <p className="subHeadingText text-coalBlack ">
                      Request Description
                    </p>
                    <p className="baseText text-navLink">
                      {data?.RequestDescription}
                    </p>
                  </div>
                  <div className="consultationType space-y-2 lg:hidden">
                    <div className="flex items-center gap-1">
                      <p className="subHeadingText text-coalBlack">
                        Consultation Type
                      </p>
                      <div className="bg-accent w-4 h-4 flex justify-center items-center rounded-full text-white text-[8px]">
                        i
                      </div>
                    </div>
                    <div className="inline-flex items-center gap-3 px-3 py-2 rounded-lg bg-lightBlue">
                      {getTypeIcon(data?.consType, 'sm')}
                      <p className="baseText text-coalBlack">{gettypeText()}</p>
                    </div>
                  </div>
                  <div className="consultationType space-y-2 lg:hidden">
                    <div className="flex items-center gap-1">
                      <p className="baseText text-coalBlack">Requested Date</p>
                    </div>
                    <div className="inline-flex items-center gap-3 px-3  pe-10 py-2 rounded-lg bg-lightBlue">
                      <div className="min-w-10 h-10 rounded-lg bg-green-100 flex justify-center items-center">
                        <CgCalendarDates className="text-accent text-xl" />
                      </div>
                      <p className="text-sm font-medium text-coalBlack capitalize">
                        {formatDate(data?.RequestedDate)}
                      </p>
                    </div>
                  </div>
                  <div className="grid-cols-2 gap-x-2 gap-y-4 consultationTypeLg hidden lg:grid">
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="subHeadingText text-[#A3AED0] px-3 py-1">
                        Consultation Type
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <div className="min-w-8 h-8 rounded-lg bg-purple-100 flex justify-center items-center">
                          {getTypeIcon(data?.consType, 'sm')}
                        </div>
                        <p className="baseText text-coalBlack">
                          {gettypeText()}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="subHeadingText text-[#A3AED0] px-3 py-1">
                        Request Priority
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <div className="min-w-4 h-8 rounded-lg  flex justify-center items-center">
                          <FaSquareCheck
                            className={`text-lg ${
                              data?.priority === 'urgent'
                                ? 'text-[#FF637B]'
                                : 'text-[#7be7b2]'
                            } `}
                          />
                        </div>
                        <p className="text-sm font-medium text-coalBlack">
                          {data?.priority === 'urgent' ? 'Urgent' : 'Routine'}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="subHeadingText text-[#A3AED0] px-3 py-1">
                        Reference Doctor
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        {/* <div className="min-w-8 h-8 rounded-full bg-gray-200"></div> */}
                        <div className="w-6 h-6 bg-sky-100 rounded-full flex justify-center items-center p-1">
                          <PiStethoscopeFill className="text-lg text-accent" />
                        </div>
                        <p className="text-sm font-medium capitalize  text-coalBlack max-w-36 truncate whitespace-nowrap text-ellipsis">
                          Dr. {data?.doctorDetails?.doctorname}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Contact Doctor
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <IoMdCall className="text-accent text-xl" />
                        <p className="text-sm font-medium text-coalBlack">
                          {data?.doctorDetails?.phone}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                      <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                        Requested Date
                      </p>
                      <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                        <CgCalendarDates className="text-accent text-xl" />
                        <p className="text-sm font-medium text-coalBlack">
                          {formatDate(data?.RequestedDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="referenceDoctor space-y-2  lg:hidden">
                    <p className="subHeadingText text-coalBlack ">
                      Reference Doctor
                    </p>
                    <div className="grid grid-cols-1  gap-2 md:grid-cols-2">
                      <div className="bg-lightBlue px-2 py-5 rounded-xl">
                        <div className="flex items-center gap-2">
                          <div className="size-10 w-10 rounded-full inline-flex justify-center items-center">
                            <PiStethoscopeFill className="text-2xl text-accent" />
                          </div>
                          <div>
                            <p className="text-coalBlack baseText max-w-[24ch] truncate text-ellipsis">
                              Dr. {data?.doctorDetails?.doctorname}
                            </p>
                            <p className="text-coalBlack font-light text-sm max-w-[24ch] truncate text-ellipsis"></p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-lightBlue px-2 py-5 rounded-xl">
                        <a href={`tel:${data?.doctorDetails?.phone}`}>
                          <div className="flex items-center gap-2">
                            <div className="size-10 w-10 rounded-full inline-flex justify-center items-center">
                              <IoMdCall className="text-accent text-2xl" />
                            </div>
                            <div>
                              <p className="text-coalBlack baseText">
                                {data?.doctorDetails?.phone}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                {data?.status === 'completed' ? (
                  <div className="lg:block hidden">
                    <div className="my-4 inline-flex items-center gap-2 mx-auto py-2 px-4 text-green-600 bg-green-200 min-h-11 rounded-2xl w-full flex items-center justify-center">
                      <div>
                        <IoMdCheckmarkCircle className="text-2xl" />
                      </div>
                      <p className=" inline-block  text-center font-medium capitalize">
                        consultation completed
                      </p>
                    </div>
                  </div>
                ) : data?.status === 'no-show' ? (
                  <div className="lg:block hidden">
                    <DisputeConsultation consultationId={currentdata} />
                  </div>
                ) : null}
                {/* prescription upload ui start  */}
                {data?.status == 'pending' ? (
                  <div className="baseText bg-white mt-4 lg:p-4 lg:rounded-3xl hidden lg:block">
                    <div className="  gap-4 ">
                      <SimpleButton
                        title={
                          loader ? <EditProfileLoader /> : 'Mark As Complete'
                        }
                        customClass={
                          'text-accent border border-accent rounded-lg mx-auto w-full'
                        }
                        onClickEvent={handledoctorcompleted}
                      />
                      {/* <SimpleButton
                        title={'Upload Prescription'}
                        buttonType={'primary'}
                        customClass={'rounded-lg'}
                        onClickEvent={handlePrescriptionBlock}
                      /> */}
                    </div>
                  </div>
                ) : null}
                {/* prescription upload ui end */}
              </section>
              <section className="space-y-6 pb-4 !mt-4 bg-white lg:rounded-3xl lg:!mt-0 lg:p-4">
                <div className="MedicalDetail space-y-2">
                  <p className="subHeadingText text-coalBlack ">
                    Medical Detail
                  </p>
                  <div className="flex flex-wrap gap-3 items-center">
                    {data?.medicalTag?.map((tag, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 rounded-full baseText text-coalBlack bg-gray-100 capitalize"
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="DoctorsDescription space-y-2">
                  <p className="subHeadingText text-coalBlack ">
                    Doctors Description
                  </p>
                  <p className="baseText text-coalBlack">{data?.doctorNotes}</p>
                </div>
                <div className="MedicalRecords space-y-2">
                  <div className="flex items-center">
                    <p className="subHeadingText text-coalBlack ">
                      Medical Records
                    </p>
                    <p
                      className="smallText text-navLink font-normal ms-auto cursor-pointer"
                      onClick={handelMedicalRecordScreen}
                    >
                      View All
                    </p>
                  </div>
                  <div className="flex items-center gap-6 px-2 py-3 rounded-lg bg-lightBlue">
                    <div className="inline-flex flex-col gap-1 justify-center items-center">
                      <BiSolidReport className="text-2xl text-accent" />
                      <p className="baseText text-accent  capitalize">Report</p>
                    </div>
                    <div className="inline-flex flex-col gap-1 justify-center items-center">
                      <FaFilePrescription className="text-2xl text-accent" />
                      <p className="baseText text-accent  capitalize">
                        Prescription
                      </p>
                    </div>
                    <div className="inline-flex flex-col gap-1 justify-center items-center">
                      <GrNotes className="text-2xl text-accent" />
                      <p className="baseText text-accent  capitalize">Notes</p>
                    </div>
                  </div>
                </div>
                {data?.hospitalId?.lat != 0 && data?.hospitalId?.log != 0 ? (
                  <div className="HospitalLocation bg-white shadow-card_shadow p-4 rounded-2xl">
                    <div className="flex justify-between items-center flex-row my-5">
                      <p className="subHeadingText text-coalBlack ">
                        Hospital Location
                      </p>
                      {!error && (
                        <p className="baseText text-gray-500 flex justify-between items-center flex-row gap-1">
                          <CiLocationArrow1 />{' '}
                          {haversineDistance(
                            location?.latitude,
                            location.longitude,
                            data?.hospitalId?.lat,
                            data?.hospitalId?.long
                          )}
                        </p>
                      )}
                    </div>

                    {data?.hospitalId?.lat ? (
                      <MapComponent
                        latitude={data?.hospitalId?.lat}
                        longitude={data?.hospitalId?.long}
                      />
                    ) : (
                      <MapComponent latitude={0} longitude={0} />
                    )}
                  </div>
                ) : null}
                {data?.Consultationstatus === 'accepted' &&
                  data?.status != 'completed' && (
                  <div className="CommunicationChannels space-y-2 hidden lg:block">
                    <p className="subHeadingText text-coalBlack ">
                        Communication Channels
                    </p>
                    <div className="flex items-center gap-6">
                      <div
                        className="flex justify-center items-center bg-accent rounded-full cursor-pointer w-full p-2 text-white"
                        onClick={handelChatBlock}
                      >
                          Chat with Hospital
                      </div>
                    </div>
                  </div>
                )}
              </section>
              {data?.status === 'completed' ? (
                <div className="lg:hidden">
                  <div className="my-4 inline-flex justify-center items-center gap-2 mx-auto py-2 px-4 text-green-600 bg-green-200 min-h-11 rounded-2xl w-full ">
                    <div>
                      <IoMdCheckmarkCircle className="text-2xl" />
                    </div>
                    <p className=" inline-block  text-center baseText !font-medium capitalize">
                      consultation completed
                    </p>
                  </div>
                </div>
              ) : data?.status === 'no-show' ? (
                <div className="lg:hidden">
                  <DisputeConsultation consultationId={currentdata} />
                </div>
              ) : null}
              {/* prescription upload ui start sm device  */}
              {data?.status == 'pending' ? (
                <div className="baseText bg-white mt-4 lg:p-4 lg:rounded-3xl lg:hidden  block">
                  <div className="grid grid-cols-1  gap-4 ">
                    <SimpleButton
                      title={
                        loader ? <EditProfileLoader /> : 'Mark As Complete'
                      }
                      customClass={
                        'text-accent border border-accent rounded-lg w-full'
                      }
                      onClickEvent={handledoctorcompleted}
                    />
                    {/* <SimpleButton
                      title={'Upload prescription'}
                      buttonType={'primary'}
                      customClass={'rounded-lg'}
                      onClickEvent={handlePrescriptionBlock}
                    /> */}
                  </div>
                </div>
              ) : null}
              {/* prescription upload ui end  sm device */}
            </div>
          </main>
          {medicalRecordScreen && (
            <MedicalRecord
              closeFunction={handelMedicalRecordScreen}
              data={data}
            />
          )}
          {/* Locaiton Promt pop up  */}
          <div
            className={`fixed top-0 left-0 p-4 w-full h-full bg-black/25  justify-center items-center z-40 ${
              LocationPromt ? 'flex' : 'hidden'
            }`}
          >
            <div className="bg-white px-4 py-8 rounded-3xl shadow-sm space-y-8 w-full max-w-sm md:px-6 md:py-6 md:space-y-10">
              <p className="text-center text-coalBlack baseText">
                Enable location services to get the distance to your designated
                hospital from your current location.
              </p>
              <div className="grid grid-cols-1">
                <SimpleButton
                  title={'Close'}
                  buttonType={'primaryUnfilled'}
                  customClass={'rounded-xl'}
                  onClickEvent={handelLocationPromt}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {chatBlock && (
        <HospitalChat
          closeFunction={handelChatBlock}
          consultationId={currentdata}
          doctorDetails={data}
        />
      )}
    </>
  );
}

export default ViewDetails;
