import React from 'react';

function RightBlock({ imgUrl }) {
  return (
    <div className="sign-in-right flex-grow w-1/2 relative hidden lg:block h-full">
      <div className="w-full h-full relative after:content-[''] after:w-full after:h-full after:bg-black/25 after:absolute after:top-0 after:right-0 after:rounded-bl-[200px]">
        {/* <div className="w-full h-full"> */}
        <img
          src={imgUrl}
          className="object-cover  w-full rounded-bl-[200px] h-screen "
        />
        {/* </div> */}
      </div>
    </div>
  );
}

RightBlock.defaultProps = {
  imgUrl:require('../../Assets/img/odds/doctor-1228629_1280.jpg'),
};

export default RightBlock;
