import React, { useContext, useEffect, useReducer, useState } from 'react';

import { FcGoogle } from 'react-icons/fc';
import { FaSquareFacebook } from 'react-icons/fa6';

import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import SignUpForm from './SignUpForm.js';
import LogInForm from './LogInForm.js';
import OneSignal from 'react-onesignal';
import { getProfileById, verifyToken } from '../../apis/index.js';
import { GoogleLogin } from '@react-oauth/google';
import { AppContext } from '../../appContext/index.js';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function GoogleSignIn({
  formGreetings,
  formSubGreetings,
  formType,
}) {
  const navigate = useNavigate();
  const  { userDetails ,setUserDetails} =useContext(AppContext);
  async function runOneSignal(id) {
    try {
     console.log(id)
     OneSignal.login(id)
      console.log('Login successful');
    } catch (error) {
      console.error('Error logging in:', error);
    }
  }
  const onVerifyToken = async (values) => {
    try {
      const response = await verifyToken(values);
      const data = response.data;
      if (response.status === 200) {
        localStorage.setItem('tempToken',response.data.token);
        localStorage.setItem('userId', response.data.userid);
        const res = await getProfileById(response.data.userid);
      if (res.status == 200) {
        if (res.data.Profile.DoctorProfile != null) {
          if (res.data.Profile.DoctorProfile.isApproved) {
            localStorage.setItem('token', response.data.token);
            setUserDetails(res.data.Profile);
            await runOneSignal(res.data.Profile.DoctorProfile?._id)
            navigate('/');
          } else {
            navigate('/registration');
          }
        } else {
          navigate('/registration');
        }
      }
      } 
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message, { id: '001' });
    }
  };
  const responseMessage = (response) => {
    console.log(response)
     const values = { token: response.credential };
     onVerifyToken(values);
  };
  const errorMessage = (error) => {
    console.log(error);
  };
 
  return (
    <>
      <section className="sign-in w-full h-full min-h-svh max-h-svh overflow-hidden flex items-center justify-center bg-white">
        <div className="sign-in-left flex  items-center flex-grow w-1/2 relative">
          <section className="flex flex-col gap-2 max-h-screen min-h-screen overflow-y-auto scrollbar w-full bg-[#FAFAFE] px-4 py-10 ">
            <div className="welMessage space-y-3">
              <h1 className="text-2xl font-medium text-center capitalize">
                {formGreetings}
              </h1>
              <p className="text-sm font-normal text-center max-w-80 text-primary_v2 mx-auto">
                {formSubGreetings}
              </p>
            </div>
            <div className="logoContainer flex justify-center mt-4">
              <div className="inline-flex mx-auto gap-2 items-center">
                <div><img src={require('../../Assets/logo/odds_logo_blue.png')} className='size-10 object-contain'/></div>
                <p className="text-lg font-semibold text-coalBlack">ODDS</p>
              </div>
            </div>
            <div className="signInFormContainer mt-16 max-w-sm mx-auto w-full lg:max-w-[400px] lg:min-w-[400px] ">
              {/* <div className="signInWithGoogle flex justify-center items-center gap-8 w-full">
                <div className="lg:hidden bg-white  shadow-blue_dropshadow px-4 py-2 rounded-2xl">
                  <SignInWithGoogleCard platform={'google'} icon={'google'} />
                </div>
                <div className="lg:block hidden w-full bg-[#F4F7FE] text-center  rounded-2xl px-4 py-3">
                  <SignInWithGoogleCard
                    platform={'Sign in with Google'}
                    icon={'google'}
                  />
                </div>
              </div> */}
                 <div className="signInWithGoogle flex justify-center items-center gap-8 w-full">
               
               <GoogleLogin text='continue_with'
               onSuccess={responseMessage} onError={errorMessage} />
           </div>
              <div className='my-4 text-navLink text-sm font-normal text-center relative after:w-full after:h-px after:bg-navLink after:contents[""] after:absolute after:-translate-y-1/2 after:top-1/2 after:left-2 '><span className="px-4 bg-[#FAFAFA] relative z-10 ">or</span></div>
              <div className="signInFormField mt-8">
                {formType == 'SIGNUP' ? <SignUpForm /> : <LogInForm />}
              </div>
            </div>
          </section>
        </div>
        <RightBlock />
      </section>
    </>
  );
}

function SignInWithGoogleCard({ platform, icon }) {
  return (
    <div className="inline-flex justify-center items-center gap-2 capitalize text-sm text-primary_v2 font-medium bg-inherit  min-w-28 text-center  rounded-xl  cursor-pointer">
      {icon == 'google' ? <FcGoogle /> : <FaSquareFacebook />}
      {platform}
    </div>
  );
}
