import React, { useState, useEffect } from 'react';

const Countdown = ({ initialSeconds ,resendOtp }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      
      return () => clearTimeout(timerId);
    }
  }, [seconds]);
  const handelResendOtp =()=>{
    if(seconds === 0){
      resendOtp();
      setSeconds(60);
    }
  };
  return (
    <div>
      <h1 className="text-red-500 font-medium text-sm tracking-[4px]">
        00:{seconds < 10 ? '0' + seconds : seconds}
      </h1>
      <p className="text-sm mt-2">
          Haven’t received code?{' '}
        <span
          className={`ms-1 font-semibold  cursor-pointer hover:underline underline-offset-2 ${seconds==0?'text-accent cursor-pointer':'text-accent/25 cursor-wait'}`}
          onClick={handelResendOtp}
        >
            Resend OTP
        </span>
      </p>
     
    </div>
  );
};

export default Countdown;
