import React, { useEffect, useState, useMemo, useCallback } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { Loader } from "../../components/common/loader";
import moment from "moment";
import DatePicker from "react-date-picker";
import calendarIcon from "../../Icons/calendarIcon.svg";
import Currency from "../../utils/Currency.json";
import { InputErrorMessage } from "../../components/errorMessages";
import { useContext } from "react";
import { EditFormContext } from "../../appContext";
import Select from "react-select";
import { useFormik } from "formik";
import { EditProfileLoader } from "../../components/common/loader";
import { brandOptions, getLastElementId } from "../../utils/helperFunctions";
import countryList from "react-select-country-list";
import { FileUploadComponent } from "../../components/loader/loader";
import { XCircleFill } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import Avatar from "../../components/Avatar/Avatar";
import influencercss from '../influencer/influencer.css';
import {
  editBriefApi,
  getAllUsers,
  getAllInternalMashers,
  fetchIndustryApi,
  createBriefApiV2,
  editBriefApiV2,
  syncLiveblocks,
  getUserApi,
  GEtinstagramapi,
  createInfluencerApi,
} from "../../apis/index";
import {
  getHqUsersFromTypesense,
  getCompaniesFromTypesense,
} from "../../apis/typesenseApis";
import nodata from '../../images/no-data.jpg';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {
  SearchBox,
  InstantSearch,
  InfiniteHits,
  RefinementList,
  SortBy,
  Configure,
  useInstantSearch,
} from "react-instantsearch";
import PhoneInput from "react-phone-number-input";
import { createInfluencerSchema } from "../Validation/ValidationSchema";
const options = [
  { value: "Instagram", label: "Instagram" },
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter", label: "Twitter" },
];
export default function Createinfluencer({ closeModal,userId }) {
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentHandelName, setCurrentHandelName] = useState("");
  const [page, setPage] = useState("page1");
  const[validdata,setvaliddata]=useState(false);
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  const formik = useFormik({
    initialValues: {
      handleId: "",
    },
    validationSchema: createInfluencerSchema,
    onSubmit: async (values) => {
      setLoader(true);
      const { handleId } = values;
      
     
      try {
         const res = await GEtinstagramapi(handleId || '');
         setLoader(false);
        if (res.status === 200 || 201) {
        
           //alert(JSON.stringify(res?.data?.business_discovery,null,2))
           var profileimg = res?.data?.business_discovery?.profile_picture_url || '';
           var username = res?.data?.business_discovery?.username || '';
           var name = res?.data?.business_discovery?.name || '';
           var posts = res?.data?.business_discovery?.media?.data?.length || 0;
           var followers = res?.data?.business_discovery?.followers_count || 0;
           var following = res?.data?.business_discovery?.follows_count || 0;
           
           formik2.setFieldValue('img', profileimg);
           formik2.setFieldValue('handleid', username);
           formik2.setFieldValue('name', name);
           formik2.setFieldValue('post', posts);
           formik2.setFieldValue('followers', followers);
           formik2.setFieldValue('following', following);
           
           if(res?.data?.business_discovery?.username){
            setvaliddata(true);
           }
           else{
            setvaliddata(false);
            const errmsg=res?.data?.error.message;
            formik2.setFieldValue('errmsg', errmsg);
           }
           setPage("page2");
           setLoader(false);

        }
        
      } catch (err) {
        toast.success(err, { id: '001' });
      }
      
    },
  });
  const formik2 = useFormik({
    initialValues: {
      img: '',
      handleid:'',
      name:'',
      post:'',
      followers:'',
      following:'',
      errmsg:'',
    },
    onSubmit: async (values) => {
      setLoader(true);
      const { handleid,name,img,post,followers,following } = values
      try {
        const payload={
          "userId":userId,
          "HandleID": handleid,
          "Photo": img,
          "Name": name,
          "posts":post,
          "Followers": followers,
          "Following": following,
          "Platform": "Instagram",
         
        }

         const res = await createInfluencerApi(payload);        
         setLoader(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });
          closeModal();
        } else {
         
          //toast.error(res?.data?.message, { id: '001' });
        }
      } catch (err) {
        toast.error(err, { id: '001' });
      }
    },
  });


  return (
    <>
      {page == "page1" ? (
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div>
                <label className="form-label">
                  {" "}
                  Enter Instagram handle ID
                </label>
                <input
                  type="text"
                  className={
                    Boolean(formik.touched?.handleId) &&
                    Boolean(formik.errors?.handleId)
                      ? "form-control border-danger"
                      : "form-control "
                  }
                  placeholder="Instagram handle ID"
                  name="handleId"
                  value={formik.values.handleId}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik.touched.handleId && formik.errors.handleId}
                  marginBottom={-5}
                />
              </div>
              <div className="brief-button-container">
                <button
                  type="button"
                  className="button-close"
                  style={{ width: "100%" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="button-primary"
                  style={{ width: "100%" }}
                >
                  {loader ? (
                    <div className="text-xs text-center">
                      <EditProfileLoader />
                    </div>
                  ) : (
                    "Preview"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : page == "page2" ? (
        <div>
          {
            validdata ? (
              <form onSubmit={formik2.handleSubmit}>
              <div className="row">
                <div className="flex flex-col gap-2.5 justify-center items-center my-3">
                 
                  {
                    formik2.values.img ?  
                    <img
                  className="instagram-img"
                  alt='...'
                  src={formik2.values.img}
                  onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                  }}
                  /> :  <Avatar name={'john'} />
  
                  }
                  <span className="handle-id">{formik2.values.handleid}</span>
                  <span className="handle-username">{formik2.values.name}</span>
                   <div className="flex-cotainer-insta">
                    <div className="flex-inshits">
                         <div className="flex-insight-num">{formik2.values.post}</div>
                         <div className="flex-insight-2ndtxt">Posts</div>
                    </div>
                    <div className="flex-inshits">
                         <div className="flex-insight-num">{formik2.values.followers}</div>
                         <div className="flex-insight-2ndtxt">followers</div>
                    </div>
                    <div className="flex-inshits">
                         <div className="flex-insight-num">{formik2.values.following}</div>
                         <div className="flex-insight-2ndtxt">following</div>
                    </div>
                    </div>
                 
                  
                </div>
                <div className="brief-button-container">
                  <button
                    type="button"
                    className="button-close"
                    style={{ width: "100%" }}
                    onClick={()=> {setPage('page1')}}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="button-primary"
                    style={{ width: "100%" }}
                  >
                    {loader ? (
                      <div className="text-xs text-center">
                        <EditProfileLoader />
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </form>
            ) :(
              <div className="row">
              <div className="flex flex-col gap-2.5 justify-center items-center my-3">
              <img
                  className="instagaram-nodata"
                  alt='...'
                  src={nodata}
                  onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                  }}
                  />
                  <span className="influencer-err">{formik2.values.errmsg}</span>
                    <div className="brief-button-container">
                  <button
                    type="button"
                    className="button-close"
                    style={{ width: "100%" }}
                    onClick={()=> {setPage('page1')}}
                  >
                    Back
                  </button>
                </div>
              </div>
              </div>
            )
          }
         
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
