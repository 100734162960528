import React, {useState, useEffect} from 'react';
import './tableList.css';
import tickGreen from '../../Icons/tickGreen.svg';

function TableListCompanies({hit, columnWidth}) {
  const [industries, setIndustries] = useState([]);

  const splitIndustries = (industries) => {
    setIndustries(industries.split(', '));
  };

  useEffect(() => {
    splitIndustries(hit.industry);
  }, []);

  return (
    <div>
      <div className='table-list-body'>
        <div className='table-list-body-item'>
          <div style={{width: columnWidth}} className='table-list-font-bold'>{hit.companyName}</div>
          <div style={{width: columnWidth}} className='table-list-font-light'>{hit.websiteLink}</div>
          <div style={{width: columnWidth}} className='table-list-font-bold table-list-flex'>
            {industries?.map(industry => {return industry && <span className='table-list-purple-badge'>{industry}</span>;})}
          </div>
          <div style={{width: columnWidth}}><span className='table-list-green-badge'>{hit.companyCountry}</span></div>
          <div style={{width: columnWidth, display: 'flex', justifyContent: 'center'}}><img src={tickGreen}/></div>
        </div>
      </div>
    </div>
  );
}

export default TableListCompanies;