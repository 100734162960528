import React, { useEffect } from 'react';
import { FcPaid } from 'react-icons/fc';
import { BsCalendarDate } from 'react-icons/bs';
import moment from 'moment';
const CompletedConsultation = ({ data, onclick }) => {
  
  return (
    <div
      className="p-4 flex gap-x-3 border-b border-gray-200 cursor-pointer"
      onClick={onclick}
      key={data?.hospitalId[0]?.hospitalName}
    >
      <div className="bg-green-100 rounded-full size-10 min-w-10 inline-flex justify-center items-center">
        <FcPaid className="text-2xl m-auto" />
      </div>
      <div>
        <p className="inline-block baseText text-coalBlack sm:!leading-5">
          You earned ₹ {data?.transactions[0]?.doctor_fee || 'N/A'} amount
          for your consultation with {' '}
          <span className="capitalize">
            {data?.hospitalId[0]?.hospitalName}
          </span>
        </p>
        <div>
          <BsCalendarDate className="text-sm inline-block me-2" />
          <small>
            {' '}
            {moment(data?.consultationCompletedDate).format('DD-MM-YYYY')}{' '}
          </small>
        </div>
      </div>
    </div>
  );
};

export default CompletedConsultation;
