import React, { useContext, useEffect, useState } from 'react';
import MobileModel from '../../components/Modals/MobileModel';
import { FaCreditCard } from 'react-icons/fa';
import { BsBank } from 'react-icons/bs';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { WalletContext } from '../../appContext';
import MainOddsPopup from '../../components/oddsPopup/MainOddsPopup';
import { CiEdit } from 'react-icons/ci';
import { SendRequest, getDoctorBankAccount } from '../../apis';
import toast from 'react-hot-toast';
import { Loader } from '../common/loader';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';

function ChooseAccount({ setStep, Amountrequested }) {
  const [bankAccountDetails, setBankAccountDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [defaultBankAccountDetails, setDefaultBankAccountDetails] = useState(
    null
  );
  const [handelWithdrawBlock, doctorProfile,setUpdatePage, updatePage] = useContext(WalletContext);
  const fetchBankAccount = async (id) => {
    try {
      const res = await getDoctorBankAccount(id);
      if (res.status == 200) {
        setLoader(!loader);
        setBankAccountDetails(res.data.user);
        setDefaultBankAccountDetails(res.data.user[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(!loader);
    }
  };
  useEffect(() => {
    fetchBankAccount(doctorProfile?.DoctorProfile?._id);
    // fetchBankAccount('66053a954c14138198a38a09');
    // 6605532399486454bf4fbee1
  }, []);
  const handleDefaultAccount = (id) => {
    bankAccountDetails.map((data, i) => {
      if (data.accountNumber === id) {
        setDefaultBankAccountDetails(data);
      }
    });
  };

  const ProceedToWithdraw = async () => {
    setLoader(true);
    try {
      const res = await SendRequest({
        accountId: defaultBankAccountDetails?._id,
        doctorId: doctorProfile?.DoctorProfile?._id,
        amount: Amountrequested || 0,
      });
      if (res.status == 200) {
        setLoader(false);
        toast.success('Request sent successfully');
        setUpdatePage(1);
        handelWithdrawBlock();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
    } finally {
      setLoader(false);
    }
    // toast.success(defaultBankAccountDetails?.accountHolderName);
  };

  const BankAccountBlock = bankAccountDetails?.map((data, key) => {
    if (data?.accountNumber === defaultBankAccountDetails?.accountNumber) {
      return (
        <div
          className="bg-lightBlue p-4 rounded-2xl  flex items-center"
          key={key}
          onClick={() => {
            handleDefaultAccount(data?.accountNumber);
          }}
        >
          <div className="inline-flex gap-3 items-center">
            <div className="size-10 bg-white rounded-full inline-flex justify-center items-center p-1">
              <FaCreditCard className="text-2xl" />
            </div>
            <div>
              <p className=" line-clamp-1 baseText !font-medium capitalize">
                {data?.accountHolderName}
              </p>
              <p className="font-medium text-navLink smallText">
                {data?.accountNumber}
              </p>
            </div>
          </div>
          <div className="inline-flex gap-3 items-center ms-auto h-full">
            <div className="bg-white  rounded-full inline-flex  items-center border border-accent  p-1">
              <div className="size-3 min-w-3 rounded-full bg-accent m-auto"></div>
            </div>
            <span
              className="ms-auto size-10 inline-flex justify-center items-center cursor-pointer"
              onClick={() => {
                setStep(4);
              }}
            >
              <CiEdit className="text-2xl inline-block " />
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="bg-lightBlue p-4 rounded-2xl  flex items-center"
          key={key}
          onClick={() => {
            handleDefaultAccount(data?.accountNumber);
          }}
        >
          <div className="inline-flex gap-3 items-center">
            <div className="size-10 bg-white rounded-full inline-flex justify-center items-center p-1">
              <FaCreditCard className="text-2xl" />
            </div>
            <div>
              <p className="line-clamp-1 baseText !font-medium capitalize">
                {data?.accountHolderName}
              </p>
              <p className="!font-medium text-navLink smallText">
                {data?.accountNumber}
              </p>
            </div>
          </div>
          <div className="inline-flex gap-3 items-center ms-auto h-full">
            <span
              className="ms-auto size-10 inline-flex justify-center items-center cursor-pointer"
              onClick={() => {
                setStep(4);
              }}
            >
              <CiEdit className="text-2xl inline-block " />
            </span>
          </div>
        </div>
      );
    }
  });

  const accountCardBody = (
    <>
      {loader ? (
        <div className="min-h-60 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex items-center">
            <div
              className="inline-flex justify-center items-center size-10 rounded-full active:bg-blue-50 cursor-pointer"
              onClick={() => {
                setStep(1);
              }}
            >
              <HiOutlineArrowSmallLeft className="text-coalBlack text-2xl" />
            </div>
            <p className="text-coalBlack subHeadingText py-2 capitalize ">
              choose account
            </p>
            <p
              className=" text-navLink ms-auto hidden lg:block baseText cursor-pointer"
              onClick={handelWithdrawBlock}
            >
              Close
            </p>
          </div>
          <div className="my-4 grid grid-cols-1 gap-4 text-coalBlack">
            {BankAccountBlock}
            {/* ADD Account block */}
            {bankAccountDetails.length < 1 && (
              <div
                className="bg-lightBlue p-4 rounded-2xl cursor-pointer "
                onClick={() => {
                  setStep(3);
                }}
              >
                <div className="inline-flex gap-3 items-center">
                  <div className="size-10 bg-white rounded-full inline-flex justify-center items-center p-1">
                    <BsBank className="text-2xl" />
                  </div>
                  <div>
                    <p className="!font-bold line-clamp-1 smallText capitalize">
                      Add bank account{' '}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-10 mb-6">
            {bankAccountDetails.length != 0 && (
              <button
                type="submit"
                className={
                  'simple-button primary rounded-2xl text-white capitalize w-full'
                }
                onClick={ProceedToWithdraw}
              >
                Proceed to Withdraw
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
  return (
    <div>
      <div className="lg:hidden">
        <MobileModel
          MobileModelBody={accountCardBody}
          popupCloseFunction={handelWithdrawBlock}
        />
      </div>
      <div className="hidden  lg:block">
        <MainOddsPopup>
          <div className="min-w-96">{accountCardBody}</div>
        </MainOddsPopup>
      </div>
    </div>
  );
}

export default ChooseAccount;
