import React, { useContext, useEffect } from 'react';
import AdminLayout from '../../components/admin-layout';
import TranstationHistory from '../Wallet/TranstationHistory';
import { AppContext } from '../../appContext';

function History() {
  const { userDetails } = useContext(AppContext);
  useEffect(() => {}, [userDetails]);
  return (
    <AdminLayout>
      <section className=" md:p-4 xl:p-5 xl:pb-0 mb-3 ">
        <div className="p-2 bg-white rounded-2xl ">
          <TranstationHistory doctorId={userDetails?.DoctorProfile?._id} />
        </div>
        {/* <div className='col-span-2'>

        </div> */}
      </section>
    </AdminLayout>
  );
}

export default History;
