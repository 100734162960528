import React, { useEffect, useState, useRef, useContext } from 'react';
import { getWithdrawHistory } from '../../apis';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import { AppContext, WalletContext } from '../../appContext';
import moment from 'moment';

const WithdrawHistory = () => {
  const [handelWithdrawBlock, doctorProfile,setUpdatePage, updatePage] = useContext(WalletContext);

  const { userDetails } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const tableHeaders = [
    {
      requestDate: 'Request date',
      withdrawDate: 'Withdraw date',
      amount: 'Amount',
      status: 'Status',
      balance: 'Balance',
    },
  ];
  const toGetWithdrawHistory = async (id) => {
    try {
      const res = await getWithdrawHistory(id);
      if (res.status === 200) {
        const array = [];
        res?.data?.WithdrawHistory?.map((data) => {
          const obj = {
            name: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {moment(data?.createdAt).format('DD-MM-YYYY')}
              </p>
            ),
            appointment: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize text-center">
                {data?.status == 'Paid'
                  ? moment(data?.paidDate).format('DD-MM-YYYY')
                  : '--'}
              </p>
            ),
            sales: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.withdrawAmt}
              </p>
            ),
            payment: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.status =='Requested'?'Pending':data?.status}
              </p>
            ),
            status: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.remainingSavingAmt}
              </p>
            ),
          };
          array.push(obj);
        });
        setData(array?.reverse());
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    toGetWithdrawHistory(userDetails?.DoctorProfile?._id);
  }, [userDetails, updatePage]);

  return loader ? (
    <div className="cardLoader h-48 lg:h-96"></div>
  ) : (
    <>
      <h1 className="text-coalBlack subHeadingText ps-2.5 pt-2.5">
        Withdraw History
      </h1>
      <PaginatedTable
        items={data}
        itemsPerPage={10}
        tableHeaders={tableHeaders}
      />
    </>
  );
};

export default WithdrawHistory;
