import React, { useContext, useEffect, useState } from 'react';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import MobileModel from '../Modals/MobileModel';
import { useFormik } from 'formik';
import { GetBankAccountDetails } from '../Validation';
import { WalletContext } from '../../appContext';
import toast from 'react-hot-toast';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import MainOddsPopup from '../oddsPopup/MainOddsPopup';
import { CreateDoctorBankAccount, getDoctorBankAccount } from '../../apis';

function GetBankDetails({ setStep }) {
  const [handelWithdrawBlock, doctorProfile] = useContext(WalletContext);

  const formik = useFormik({
    initialValues: {
      holderName: '',
      accountNumber: '',
      ifscCode: '',
    },
    validationSchema: GetBankAccountDetails,

    onSubmit: async (values) => {
      const { holderName, accountNumber, ifscCode } = values;
      const payload = {
        accountHolderName: holderName,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
        doctorId: doctorProfile?.DoctorProfile?._id,
        doctorName: doctorProfile?.DoctorProfile?.DoctorName,
      };
      try {
        const res = await CreateDoctorBankAccount(payload);
        if (res.status == 200) {
          toast.success('Account Added Successfully');
          setStep(2);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const [conformPopUp, setConformPopUp] = useState(false);
  function handelConfirmPopUp() {
    setConformPopUp(!conformPopUp);
  }

  const accountCardBody = (
    <div>
      <div className="flex items-center">
        <p className="text-coalBlack subHeadingText py-2 capitalize text-center">
          Add Bank Account
        </p>
        <p
          className="text-navLink ms-auto hidden lg:block baseText cursor-pointer"
          onClick={handelWithdrawBlock}
        >
          close
        </p>
      </div>
      <form className="!my-4 space-y-4">
        <div className="AccountHolderName">
          <p className="baseText text-coalBlack mb-2 capitalize">
            Account Holder Name
          </p>
          <div className="relative">
            <input
              type="text"
              name="holderName"
              className={
                Boolean(formik.touched.holderName) &&
                Boolean(formik.errors.holderName)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="holderName"
              placeholder="Enter name"
              value={formik.values.holderName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="accountNumber">
          <p className="baseText text-coalBlack mb-2 capitalize">
            Bank Account Number
          </p>
          <div className="relative">
            <input
              type="text"
              name="accountNumber"
              className={
                Boolean(formik.touched.accountNumber) &&
                Boolean(formik.errors.accountNumber)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="accountNumber"
              placeholder="Enter Bank Account Number"
              value={formik.values.accountNumber}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="ifscCode">
          <p className="baseText text-coalBlack mb-2 capitalize">
            IFSC Code
          </p>
          <div className="relative">
            <input
              type="text"
              name="ifscCode"
              className={
                Boolean(formik.touched.ifscCode) &&
                Boolean(formik.errors.ifscCode)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="ifscCode"
              placeholder="Enter IFSC Code"
              value={formik.values.ifscCode}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
      <div className="my-4 grid grid-cols-1 gap-4 text-coalBlack"></div>
      <div className="mt-10 mb-6">
        <SimpleButton
          customClass={'rounded-2xl text-white capitalize w-full'}
          buttonType={'primary'}
          title={'add account'}
          onClickEvent={handelConfirmPopUp}
        />
      </div>
    </div>
  );

  return (
    <section>
      <div className="lg:hidden">
        <MobileModel
          MobileModelBody={accountCardBody}
          popupCloseFunction={handelWithdrawBlock}
        />
      </div>
      <div className="hidden lg:block">
        <MainOddsPopup>
          <div className="min-w-96">{accountCardBody}</div>
        </MainOddsPopup>
      </div>
      {conformPopUp && (
        <ConfirmPopup
          message={'Are you sure  want to add this account?'}
          handelConfirmPopUp={handelConfirmPopUp}
          popupStatus={conformPopUp}
          onclickFunction={handleSubmit}
        />
      )}
    </section>
  );
}

export default GetBankDetails;
