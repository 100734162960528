import React, {useState, useEffect,useContext} from 'react';
import toast from 'react-hot-toast';
import { redirect, useLocation } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import MasherTeamUpTable from './MasherTeamUpTable/MasherTeamUpTable';
import PriceTable from './PricingTable/PricingTable';
import TableListHeaderDeliverables from '../../components/TableList/TableListHeaderDeliverables';
import TableListDeliverables from '../../components/TableList/TableListDeliverables';
import { getBriefByIdApiV2, briefToProjectV2, syncLiveblocks } from '../../apis';
import { getLastElementId } from '../../utils/helperFunctions';
import { EditProfileLoader } from '../../components/common/loader';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import { AppContext } from '../../appContext';

function  BriefToProject() {

  const [briefData, setBriefData] = useState();
  const [saving, setSaving] = useState(false);
  const loc = useLocation();
  const briefId = getLastElementId(loc.pathname);
  const { loggedInUser } = useContext(AppContext);
  const userId = loggedInUser?.data[0]?._id;
  const getBriefData = async (id) => {
    try{
      const res = await getBriefByIdApiV2(id);
      if (res?.status === 200) {
        setBriefData(res.data?.brief);
      } else {
        console.log('Retrying..');      
        const retry = await getBriefByIdApiV2(id);
        setBriefData(retry.data?.brief);
      }
    } catch(e) {
      console.error('Failed to get brief data', e);
      console.log('Retrying..');      
      const retryOnError = await getBriefByIdApiV2(id);
      setBriefData(retryOnError.data?.brief);
    }
  };
  useEffect(() => {
    getBriefData(briefId);
  }, []);

  const tableHeaders = [
    {
      title: 'Deliverable'
    },
    {
      title: 'Dates'
    }
  ];

  const handleConverToProject = async () => {
    try {
      setSaving(true);
      
      // await syncLiveblocks({
      //   briefId,
      // });

      const res = await briefToProjectV2({
        userID:userId,
        briefid: briefId
      });
      setSaving(false);
      if (res.status === 200 || 201) {
        toast.success(res?.data?.message, { id: '001' }); 
        window.location.href = `/campaigns/campaigns-view/${res?.data?.project?._id}`;
      } else {
        toast.error(res?.data?.message, { id: '001' }); 
      }
    } catch(e) {
      toast.error(e?.response?.data?.error, { id: '001' }); 
      setSaving(false);
    }
  };

  return (
    <>
      <AdminLayout>

        <PageHeader title={briefData?.name} briefId={briefId} buttonsArray={[
          <SimpleButton
            title={ saving ? <EditProfileLoader/> : 'Submit'}
            altText='Convert Brief to Project'
            buttonType='primary'
            buttonFunction='openModal'
            onClickEvent={() => {
              handleConverToProject();
            }}
          />
        ]} />
        {briefData ? <div className='search-card-table-container' style={{padding: '15px 55px 12px 70px'}}>
          <div className='project-mashup-table-title-block'>Deliverables</div>
          <TableListHeaderDeliverables headers={tableHeaders} />
          <div className="align-items-start search-container">
            {/* <EditProfileLoader /> */}
            <div className="col-12 search-card-main-window">
              <TableListDeliverables briefId={briefId} deliverables={briefData.deliverables}/>
            </div>
          </div>
        </div> : <EditProfileLoader/>}
        <div className='container-fluid'>
          {briefData ? 
            <MasherTeamUpTable briefData={briefData} convertToProject={true}  refreshBrief={() => {
              getBriefData(briefId);
            }} />
            : <EditProfileLoader />
          }
          {/* <div style={{width: '100%', height: '65px'}}></div>
          {briefData ? 
            <PriceTable isConvertToProject={true} briefData={briefData} />
            : <EditProfileLoader />
          } */}
        </div>
      </AdminLayout>
    </>
  );
}

export default BriefToProject;