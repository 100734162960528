import React, { useState, useEffect,useContext } from 'react';
const countries = require('i18n-iso-countries');
import PageHeader from '../../components/pageElements/pageHeader/PageHeader.js';
import AdminLayout from '../../components/admin-layout/index.js';
import { UserContext, EditFormContext } from '../../appContext/index.js';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseProjectListComponent from '../../components/TypesenseList/TypesenseProjectListComponent.js';
import plusIconCircle from '../../Icons/plusIconCircle.svg';
import { Download } from 'react-bootstrap-icons';
import { API_BASE_URL } from '../../utils/baseurl.js';
import { AppContext } from '../../appContext/index.js';
import axios from 'axios';

export default function ProjectListView() {
  const [mashHqUsers, setMashHqUsers] = useState();

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countryName = countries.getNames('en', {select: 'official'});
  const countryCode = countries.getNumericCodes();
  const countriesList = Object.keys(countryCode).map((code, i) => {
    const iso = countryCode[code];
    return {
      id: code,
      countryISO: iso,
      countryName: countryName[iso]
    };
  });

  const getHqUsers = async () => {
    await axios.get(`https://${process.env.REACT_APP_HOST_NAME_NEW}/collections/hqusers/documents/search?q=*&query_by=`, {
      headers: {
        'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
      }
    })
      .then((res) => setMashHqUsers(res.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getHqUsers();
  }, []);

  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'campaigns',
          query_by: 'companyName, name, owner.firstName',
          facet_by: 'status, owner.firstName, companyCountry.countryName',
          sort_by: 'createdAt:desc'
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  
  const searchClient = typesenseInitiate();

  const tableHeaders = [
    {
      title: 'Name'
    },
    {
      title: 'Company'
    },
    {
      title: 'Brief Owner'
    },
    {
      title: 'Status'
    },
    {
      title: 'Expected Start Date'
    },
    {
      title: 'Expected End Date'
    }
  ];
  const columnWidth = () => {
    return `${100 / tableHeaders.length}%`;
  };
  const { loggedInUser } = useContext(AppContext);
  const userId = loggedInUser?.data[0]?._id;

  return (
    <AdminLayout>
      <UserContext.Provider value>
        <PageHeader title='Campaigns' 
        buttonsArray={[
          <button
              className="simple-button primary-unfilled"
              role="link"
              // onClick={startBriefDetails}
            >
              <a href={`${API_BASE_URL}/api/v1/common/exports/downloadExcel?userId=${userId}`} className="flex items-center gap-2">
              <Download />
               Report
              </a>
           
            </button>

        ]}
        
        />
        <div>
          <article>
            <main>
              <TypesenseProjectListComponent
                searchClient={searchClient}
                headers={tableHeaders}
                columnWidth={columnWidth()}
                hqUsers={mashHqUsers}
                blockTitle='Campaigns'
                showStatusOptions={true}
                showPropDueDateOptions={true}
                showBriefOwnerOptions={true}
                showSearchOptions={true}
                tablePaddingRequired={true}
              />
            </main>
          </article>
        </div>
      </UserContext.Provider>
    </AdminLayout>
  );
}
