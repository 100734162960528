import { useEffect, useState, useRef } from 'react';
import {
  SearchBox,
  InstantSearch,
  InfiniteHits,
  RefinementList,
  SortBy,
  Configure,
  useInstantSearch,
} from 'react-instantsearch';
import { getAllMashersApi, getDoctorApibyUserID, getUserApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';
import MashCard from '../cards/MashCard';
import './globalSearch.css';
import ViewDetails from '../ConsultationCard/ViewDetails';
import ConsultationCard from '../ConsultationCard/ConsultationCard';
import BigLoader from './BigLoader';
export default function TypesenseConsultationListComponent({
  searchClient,
  type,
  filterval,
  onload,
  sortOpt
}) {
  const [searchWindowClassName, setSearchWindowClassName] = useState(
    'search-box-visible'
  );
  const MainContainer = () => {
    const { status, refresh, results } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? (
      <BigLoader />
    ) : <InfiniteHits
    className="search-row list-style-masher"
    hitComponent={(hit) => (
      <ConsultationCard
        {...hit}
        data={hit.hit}
        ViewRequest={() => {
          handelViewRequestBlock(hit.hit);
        }}
        editRequest={() => {
          handelEditRequestBlock(hit.hit);
        }}
        onload={onload}
        ispage="seperate"
        fillBtnTitle="View Details"
        unFillBtnTitle="Delete"
        fillBtnfun={() => {
          handelViewRequestBlock(hit.hit);
        }}
      />
    )}
  />;
  };
  const LoadingIndicator = () => {
    const { status, refresh } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? (
      <EditProfileLoader />
    ) : null;
  };

  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
    if (error) {
      setSearchWindowClassName('search-window-hidden');
      return (
        <div>
          <span className="d-flex align-items-center">
            <h5 className="m-1">Note: </h5>
            <p className="m-1">
              {' '}
              Use Control/Command + F to search for a Consultations
            </p>
          </span>
        </div>
      );
    }

    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };

  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div className="no-results-container">
        <p className='baseText text-coalBlack'>No Consultations found.</p>
      </div>
    );
  };

  const [editRequestBlock, setEditRequestBlock] = useState(false);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const ButtonRef = useRef(null);
  const [currentrequest, setcurrentrequest] = useState(null);
  function handelEditRequestBlock(data) {
    setEditRequestBlock(!editRequestBlock);
    setcurrentrequest(data);
  }
  function handelViewRequestBlock(data) {
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata', data?._id);
    setcurrentrequest(data?._id);
  }
  const [profileData, setprofileData] = useState('');
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log('profile id', res?.data?.Profile?._id);
        fetchDoctor(res?.data?.Profile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDoctor = async (id) => {
    try {
      const res = await getDoctorApibyUserID(id);
      if (res.status === 200) {
        console.log(res.data.Profile);

        setprofileData(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="col-12">
      <div className="global-search">
        <InstantSearch
          indexName="odds-hospitalConsultation"
          searchClient={searchClient}
          routing={true}
        >
          <NoResultsBoundary fallback={<NoResults />}></NoResultsBoundary>
          <Configure
            hitsPerPage={15}
            queryBy={type}
            query={filterval}
            filters={
              profileData?.DoctorProfile?._id
                ? `status:${sortOpt}&&AcceptedDoctor._id:${profileData?.DoctorProfile?._id}&&Specalaization.name:${profileData?.DoctorProfile?.specialization.name}`
                : undefined
            }

          />
          <MainContainer />
        </InstantSearch>
      </div>

      {ViewRequestBlock && (
        <ViewDetails
          closeFunction={handelViewRequestBlock}
          currentdata={currentrequest}
          profileData={profileData}
        />
      )}
    </div>
  );
}
