import { useFormik } from 'formik';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  changePassword,
  createUser,
  forgotPassword,
  getProfileById,
  loginApi,
  otpVerify,
  userAuth,
} from '../../apis/index.js';
import {
  ForgotPasswordEmailValidation,
  ResetPasswordValidation,
  SignInValidation,
  SignupValidation,
  WltSignInValidation,
} from '../../components/Validation/index.js';

import { InputErrorMessage } from '../../components/errorMessages/index.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';


function SignUpForm() {
    const onCreateUser = async (values) => {
      try {
        setLoader(true);
        await createUser(values);
        setLoader(false);
        navigate('/sign-in');
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };
    const formik = useFormik({
      initialValues: {
        email: '',
        // userName: '',
        password: '',
        isTermsAccepted: false,
      },
      validationSchema: SignupValidation,
      onSubmit: async (values) => {
        console.log(values);
        try {
          setLoader(true);
          const res = await createUser(values);
          if (res.status === 200) {
            toast.success(res.data.message);
            navigate('/sign-in');
          }
        } catch (error) {
          setLoader(false);
          const message =
            error.response.data.message || error.response.statusText;
          toast.error(message, { id: 'error' });
        }
      },
    });
    const { handleSubmit, handleChange, values, touched, errors } = formik;
  
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState('password');
    function handelPasswordType() {
      if (passwordType == 'text') {
        setPasswordType('password');
      } else if (passwordType == 'password') {
        setPasswordType('text');
      }
    }
    return (
      <form className="signInForm space-y-3" onSubmit={handleSubmit}>
        {/* <div className="relative">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
            Name
          </p>
          <input
            type="text"
            name="userName"
            className={
              Boolean(formik.touched.userName) && Boolean(formik.errors.userName)
                ? 'form-control border-danger transparent-input-border'
                : 'form-control transparent-input-border'
            }
            id="userName"
            placeholder="Name"
            value={formik.values.userName}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={formik.touched.userName && formik.errors.userName}
          />
        </div> */}
        <div className="relative">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
            Email
          </p>
          <input
            type="text"
            name="email"
            className={
              Boolean(formik.touched.email) && Boolean(formik.errors.email)
                ? 'form-control border-danger transparent-input-border'
                : 'form-control transparent-input-border'
            }
            id="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="relative">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
            password
          </p>
          <div className="relative">
            <input
              type={passwordType}
              name="password"
              className={
                Boolean(formik.touched.password) &&
                Boolean(formik.errors.password)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="password"
              placeholder="Password "
              value={formik.values.password}
              onChange={handleChange}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelPasswordType}
            >
              {passwordType == 'password' ? (
                <IoEyeOff />
              ) : passwordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={formik.touched.password && formik.errors.password}
          />
        </div>
        <div className="termsCondition">
          <div className=" flex items-start mt-6">
            <input
              type="checkbox"
              className="mt-0.5"
              id="isTermsAccepted"
              name="isTermsAccepted"
              checked={values.isTermsAccepted}
              onChange={handleChange}
            />
            <label
              className="text-xs text-primary_v2 ms-1 select-none"
              htmlFor="isTermsAccepted"
            >
              I agree with the Terms of Service & Privacy Policy
            </label>
          </div>
          <InputErrorMessage
            error={
              formik.touched.isTermsAccepted && formik.errors.isTermsAccepted
            }
          />
        </div>
        <div className="signButton !mt-14 space-y-2">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Sign Up'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
          <p
            className="text-sm text-accent font-normal text-center cursor-pointer select-none"
            onClick={() => navigate('/log-in')}
          >
            Have an account? Log in
          </p>
        </div>
      </form>
    );
  }

export default SignUpForm