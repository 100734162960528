import React, { useEffect, useState, useRef, useContext } from 'react';
import AdminLayout from '../../components/admin-layout';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { CgSortAz } from 'react-icons/cg';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import Select from 'react-select';
import TypesenseMasherListComponent from '../../components/TypesenseList/TypesenseMasherListComponent';
import TypesenseConsultationListComponent from '../../components/TypesenseList/TypesenseConsultation';
import LoaderCard from '../../components/ConsultationCard/LoaderCard';
import { getAllConsultation } from '../../apis';
import ViewDetails from '../../components/ConsultationCard/ViewDetails';
import { AppContext } from '../../appContext';
function Index() {
  const { userDetails } = useContext(AppContext);
  const [tabType, setTabType] = useState('All');
  const sort = ['Newest', 'Oldest'];
  const sortOption = sort.map((el) => {
    const container = {};
    container['value'] = 'test';
    container['label'] = 'test2';

    return container;
  });
  const hiddenButtonRef = useRef(null);
  const [sortOpt, setSortOpt] = useState('pending');
  const [type, settype] = useState('');
  const [filterval, setfilterval] = useState('');
  const [loader, setLoader] = useState(true);
  const [reload, setReload] = useState(true);
  const [data, setData] = useState(null);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  function handelTabs(event, prop) {
    setTabType(prop);
    if (prop === 'Remote') {
      settype('consType');
      setfilterval('virtual');
    } else if (prop === 'InPerson') {
      settype('consType');
      setfilterval('inperson');
    } else if (prop === 'Routine') {
      settype('priority');
      setfilterval('regular');
    } else if (prop === 'Urgent') {
      settype('priority');
      setfilterval('urgent');
    } else {
      settype('');
      setfilterval('');
    }
    const tabElm = document.querySelectorAll('.tabBtn');
    const removeClass = ['bg-accent', 'text-white'];
    const addClass = ['bg-accent', 'text-white'];
    tabElm.forEach((data) => {
      data.classList.remove(...removeClass);
    });
    event.target.classList.add(...addClass);
  }

  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-hospitalConsultation',
          sort_by: 'createdAt:asc',
          query_by: '*',
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  function onload() {
    // hiddenButtonRef.current.click();
    // location.reload();
    setReload(!reload);
  }
  const [reloadtext, setreloadtext] = useState('');
  const toGetAllConsultation = async (id, sortOpt, filterType, filterValue) => {
    try {
      setLoader(true);
      const res = await getAllConsultation(
        id,
        sortOpt,
        filterType,
        filterValue
      );
      console.log(res);
      if (res.status === 200) {
        setData(res?.data?.AllHospitalConsultation);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    toGetAllConsultation(
      userDetails?.DoctorProfile?._id,
      sortOpt,
      type,
      filterval
    );
  }, [tabType, reload, sortOpt, userDetails]);

  const notFound = (
    <div className="h-72 flex justify-center items-center col-span-3 3xl:col-span-4">
      <p className="baseText text-navLink capitalize">
        No Consultations found.
      </p>
    </div>
  );
  function centerTab(tabId) {
    const tabsContainer = document.getElementById('tabs-container');
    const tab = document.getElementById(tabId);
    if (tab && tabsContainer) {
      const containerWidth = tabsContainer.clientWidth;
      const tabWidth = tab.clientWidth;

      const offset = tab.offsetLeft - containerWidth / 2 + tabWidth / 2;

      tabsContainer.scrollTo({
        left: offset,
        behavior: 'smooth',
      });
    }
  }
  function handelViewRequestBlock(data) {
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata', data?._id);
    setCurrentRequest(data);
  }
  console.log(userDetails);
  return (
    <>
      <AdminLayout>
        <section className="pt-4 xl:p-5 xl:pb-0">
          <div className="flex justify-between items-center rounded-lg bg-white p-4 xl:p-5 ">
            <p className=" text-coalBlack headingText capitalize">
              request history
            </p>
          </div>
          <section className="bg-white p-4 rounded-sm mt-1 mb-14 xl:mb-0 xl:mt-14 xl:p-6">
            <div
              className="bg-neutral-100 rounded-lg p-2 flex items-center gap-1 overflow-x-auto "
              id="tabs-container"
            >
              <button
                id="tab1"
                className={
                  'tabBtn px-4 py-2 text-coalBlack baseText rounded-lg capitalize whitespace-nowrap bg-accent text-white'
                }
                onClick={(e) => {
                  handelTabs(event, 'All');

                  centerTab('tab1');
                }}
              >
                All
              </button>
              <button
                id="tab2"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap'
                }
                onClick={(e) => {
                  handelTabs(event, 'Remote');

                  centerTab('tab2');
                }}
              >
                remote
              </button>
              <button
                id="tab3"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap'
                }
                onClick={(e) => {
                  handelTabs(event, 'InPerson');
                  centerTab('tab3');
                }}
              >
                in-person
              </button>
              <button
                id="tab4"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap '
                }
                onClick={(e) => {
                  handelTabs(event, 'Urgent');

                  centerTab('tab4');
                }}
              >
                urgent
              </button>
              <button
                id="tab5"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap }'
                }
                onClick={(e) => {
                  handelTabs(event, 'Routine');
                  centerTab('tab5');
                }}
              >
                Routine
              </button>
            </div>
            <div className="mt-6 flex justify-between items-center ">
              <div className="inline-flex gap-4 items-center">
                <p className="baseText text-coalBlack capitalize">
                  {tabType} Consultations
                </p>
              </div>
              <div className="inline-flex items-center cursor-pointer relative group/sort">
                <CgSortAz className="text-3xl text-accent font-light" />
                <p className="text-coalBlack baseText !font-medium">Sort</p>
                <div className="absolute z-50 top-full -left-full bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[125px] invisible group-hover/sort:visible">
                  <p
                    className={`${
                      sortOpt == 'completed'
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1 `}
                    onClick={() => {
                      setSortOpt('completed');
                    }}
                  >
                    Completed
                  </p>
                  <p
                    className={`${
                      sortOpt == 'pending'
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1`}
                    onClick={() => {
                      setSortOpt('pending');
                    }}
                  >
                    Pending
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6  grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4">
              {/* {consultationBlock} */}
              {/* <TypesenseConsultationListComponent
                searchClient={searchClient}
                type={type}
                settype={settype}
                setfilterval={setfilterval}
                filterval={filterval}
                createRequestBlock={createRequestBlock}
                setCreateRequestBlock={setCreateRequestBlock}
                handelCreateRequestBlock={handelCreateRequestBlock}
                onload={onload}
                sortOpt={sortOpt}
              /> */}
              {loader ? (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              ) : data?.length != 0 ? (
                data?.map((item, key) => (
                  <ConsultationCard
                    data={item}
                    onload={onload}
                    fillBtnfun={() => handelViewRequestBlock(item)}
                  />
                ))
              ) : (
                notFound
              )}
            </div>
          </section>
        </section>
        {ViewRequestBlock && (
          <ViewDetails
            closeFunction={handelViewRequestBlock}
            currentdata={currentRequest?._id}
            // profileData={profileData}
          />
        )}
      </AdminLayout>
    </>
  );
}

export default Index;
