import React, {useState} from 'react';
import RoleCardProject from './RoleCardProject';
import './masherTeamUpTableProject.css';


export default function MasherTeamUpTableProject({ briefData }) {
  return (
    <>
      <div className='project-mashup-table-title-block'>Mash-Up Team</div>

      <div className='project-mashup-table-header-row'>
        <div className='project-role-card-masher-details-left'>
          <p style={{width: '450px', paddingLeft: '25px'}} >Deliverables/Platforms</p>
          <p>Name</p>
        </div>   
      </div>
      <div className='project-mashup-table-card-container'>
        {
          briefData.deliverables &&
            briefData.deliverables?.map((deliverable, i) => {
              return <RoleCardProject deliverable={deliverable} key={i} index={deliverable.deliverableId} />;
            })
          
        }
      </div>
    </>
  );

}