import React from "react";
import './globalSearch.css';

export default function BigLoader(){
    return(
        <div className="relative" style={{ height: '200px' }}>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="w-16 h-16 border-4 border-sky-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  </div>
    )
}