import { useEffect, useState, useRef } from 'react';
import {
  SearchBox,
  InstantSearch,
  InfiniteHits,
  RefinementList,
  SortBy,
  Configure,
  useInstantSearch,
} from 'react-instantsearch';
import { getAllMashersApi, getDoctorApibyUserID, getUserApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';
import MashCard from '../cards/MashCard';
import './globalSearch.css';
import ViewDetails from '../ConsultationCard/ViewDetails';
import ConsultationCard from '../ConsultationCard/ConsultationCard';
import ViewRequest from '../ConsultationCard/Viewrequest';
// import Createrequest from '../ConsultationCard/Createrequest';
// import ViewRequest from '../ConsultationCard/Viewrequest';
// import ConsultationViewRequest from '../ConsultationCard/ConsultationViewRequest';

export default function TypesenseBroadcastConsultationListComponent({
  searchClient,
  type,
  filterval,
  settype,
  setfilterval,
  createRequestBlock,
  setCreateRequestBlock,
  handelCreateRequestBlock,
  onload,
}) {
  const [reqId, setReqId] = useState();
  const [mashersFromApi, setMashersFromApi] = useState([]);
  const [
    showFilterForMasherApproved,
    setShowFilterForMasherApproved,
  ] = useState(false);
  const [searchWindowClassName, setSearchWindowClassName] = useState(
    'search-box-visible'
  );
  const toggleMasherApprovedOpen = () => {
    setShowFilterForMasherApproved(!showFilterForMasherApproved);
  };

  const LoadingIndicator = () => {
    const { status, refresh } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? (
      <EditProfileLoader />
    ) : null;
  };

  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
    if (error) {
      setSearchWindowClassName('search-window-hidden');
      return (
        <div>
          <span className="d-flex align-items-center">
            <h5 className="m-1">Note: </h5>
            <p className="m-1">
              {' '}
              Use Control/Command + F to search for a masher
            </p>
          </span>
          {mashersFromApi?.map((masher, i) => {
            return <MashCard hit={masher} key={i} />;
          })}
        </div>
      );
    }

    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };

  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div className="no-results-container">
        {/* <p>
          No results found <q>{indexUiState.query}</q>.
        </p> */}
        <p>No Consultations found.</p>
      </div>
    );
  };

  const [editRequestBlock, setEditRequestBlock] = useState(false);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const ButtonRef = useRef(null);
  const [currentrequest, setcurrentrequest] = useState(null);
  function handelEditRequestBlock(data) {
    setEditRequestBlock(!editRequestBlock);
    
    setcurrentrequest(data);
  }
  function handelViewRequestBlock(data) {
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata',data?._id);
    setcurrentrequest(data?._id);
    setReqId(data?._id);
  }
  const [profileData, setprofileData] = useState('');
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log('profile id',res?.data?.Profile?._id);
        fetchDoctor(res?.data?.Profile?._id);
        

      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDoctor = async (id) => {
    try {
      const res = await getDoctorApibyUserID(id);
      if (res.status === 200) {
        console.log(res.data.Profile);
        
        setprofileData(res?.data?.Profile);

      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function getStateWithoutPage(state) {
    const { page, ...rest } = state || {};
    return rest;
  }

  function getInMemoryCache() {
    let cachedHits = null;
    let cachedState = null;
    return {
      read({ state }) {
        return isEqual(cachedState, getStateWithoutPage(state))
          ? cachedHits
          : null;
      },
      write({ state, hits }) {
        cachedState = getStateWithoutPage(state);
        cachedHits = hits;
      },
      invalidate() {
        cachedHits = null;
        cachedState = null;
      },
    };
  }

  // const cache = getInMemoryCache();

  // function Refresh() {
  //   const { refresh, setUiState } = useInstantSearch();

  //   function resetAndRefresh() {
  //     setUiState((uiState) => ({
  //       ...uiState,
  //       instant_search: {
  //         ...uiState.instant_search,
  //         page: 0
  //       }
  //     }));
  //     cache.invalidate();
  //     refresh();
  //   }

  //   return <button onClick={resetAndRefresh} ref={ButtonRef}>Refresh</button>;
  // }

  console.log(profileData);

  return (
    <div className="col-12">
      <div className="global-search">
        <InstantSearch
          indexName="odds-hospitalConsultation"
          searchClient={searchClient}
          routing={true}
        
          // filters={'hospitalId:660e988c803eff71d6a91cdf'}
        >
          <NoResultsBoundary fallback={<NoResults />}></NoResultsBoundary>
          <Configure
            hitsPerPage={15}
            queryBy={type}
            query={filterval}
            filters={`Consultationstatus:pending&&Specalaization.name:${profileData?.DoctorProfile?.specialization.name}&&RequestedDatetypesense:>=${Math.floor(Date.now() / 1000)}`}
          />
          {/* filters={`AcceptedDoctor:${profileData?._id}`} */}
          {/* <main className={`${searchWindowClassName} search-box-header`}>
            <div className='search-box-container'>
              <SearchBox placeholder="Search for users using either their Handle ID or username." />
              <span className="search-box-options-text">Search for users using either their Handle ID or username.", e.g. “whiteleaf_talent” or “WLT”.</span>
            </div>
              
            <div className="filter-sort-container">
              <div className="search-card-sort-container">
                <label>Sort by</label>
                <SortBy
                  classNames={{ option: 'btn btn_primary sort-dropdown' }}
                  defaultrefinement="influencer"
                  items={[
                    { label:'latest', value: 'influencer/sort/createdAt:desc'},
                    { label:'earliest', value: 'influencer/sort/createdAt:asc'},
                  ]}
                />
              </div>
            </div>
          </main> */}
          <LoadingIndicator />
          <InfiniteHits
            className="search-row list-style-masher"
            hitComponent={(hit) => (
              //<MashCard {...hit} currentid={currentid} setcurrentid={setcurrentid} />
              <ConsultationCard
                {...hit}
                data={hit.hit}
                ViewRequest={() => {
                  handelViewRequestBlock(hit.hit);
                }}
                editRequest={() => {
                  handelEditRequestBlock(hit.hit);
                }}
                onload={onload}
                ispage="seperate"
                fillBtnTitle="View Details"
                unFillBtnTitle="Delete"
                fillBtnfun={() => {
                  handelViewRequestBlock(hit.hit);
                }}
              />
            )}
          />
          {/* <Refresh /> */}
        </InstantSearch>
      </div>

      {ViewRequestBlock && (
        <ViewRequest
          closeFunction={handelViewRequestBlock}
          reqId={reqId} data={currentrequest}
          onload={onload}
        
        />
      )}
    </div>
  );
}
