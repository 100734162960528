import React, { useState, useContext, useEffect, useRef } from 'react';
import AdminLayout from '../../components/admin-layout';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import { AppContext } from '../../appContext';
import {
  getDoctorApibyUserID,
  getNotificationAPI,
  getOddsWeavyTokenApi,
  getUserApi,
} from '../../apis';

import { EditProfileLoader } from '../../components/common/loader';
import ViewDetails from '../../components/ConsultationCard/ViewDetails';
import WeavyNotification from '../../components/oddsChat/component/WeavyNotification';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';

function NotificationOdds() {
  const { chatCount } = useContext(AppContext);

  const weavyInstanceRef = useRef(null); // Use useRef to keep a mutable reference
  const [notification, setNotification] = useState(null);
  const [profileData, setprofileData] = useState('');
  const [currentRequest, setCurrentRequest] = useState(null);
  const [viewRequestBlock, setViewRequestBlock] = useState(false);
  const [loader, setloader] = useState(false);
  const [tab, setTab] = useState(1);
  const handleTabs = (prop) => {
    setTab(prop);
  };
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log('profile id', res?.data?.Profile?._id);
        fetchDoctor(res?.data?.Profile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDoctor = async (id) => {
    try {
      const res = await getDoctorApibyUserID(id);
      if (res.status === 200) {
        console.log(res.data.Profile);

        setprofileData(res?.data?.Profile);
        getNotification(res?.data?.Profile?.DoctorProfile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNotification = async (userId) => {
    try {
      console.log(userId);
      const res = await getNotificationAPI(userId);
      if (res.status === 200) {
        console.log(res.data);
        setNotification(res.data.doctorNotification);
      }
    } catch (error) {
      //console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
    // getUserDetails(localStorage.getItem('userId'));
  }, []);

  const [viewRequestBlockfull, setViewRequestBlockfull] = useState(null);
  const [reqId, setReqId] = useState();
  function handelViewCreateRequestBlockfull(prop) {
    setReqId(prop);
    setViewRequestBlockfull(!viewRequestBlockfull);
  }

  const mapFunctionWithNullCheck = (dataArray, component) => {
    if (!dataArray) return null;
    return dataArray.map((data, i) => {
      return (
        <MessageCard
          data={data}
          notificationfun={() => {
            getNotification(profileData?.DoctorProfile?._id);
          }}
          viewFunction={handelViewCreateRequestBlockfull}
          setcurrentdata={setCurrentRequest}
          profileData={profileData}
          key={i}
        />
      );
    });
  };

  const allMsgBlock = mapFunctionWithNullCheck(
    notification?.allNotification,
    MessageCard
  );
  const readMsgBlock = mapFunctionWithNullCheck(
    notification?.readNotification,
    MessageCard
  );
  const unreadMsgBlock = mapFunctionWithNullCheck(
    notification?.unReadNotification,
    MessageCard
  );

  return (
    <AdminLayout>
      <div className="p-4 md:px-8 2xl:container 2xl:mx-auto bg-white min-h-screen pb-20 xl:pb-0">
        <div className="flex items-center gap-2 min-h-10 mb-2 xl:hidden sticky top-[-1px] py-3 bg-white z-10">
          <HiOutlineArrowSmallLeft
            className="text-base text-coalBlack p-1.5 w-9 h-9 rounded-full active:bg-blue-50 lg:hidden"
            onClick={() => {
              window.history.back();
            }}
          />
          <p className="subHeadingText text-coalBlack">Notification</p>
        </div>
        <div className="flex items-center gap-4 mt-5 p-4 border-b border-gray-200">
          <p
            className={`baseText cursor-pointer capitalize border-b-[3px]  hover:text-accent  ${
              tab == 1
                ? 'border-accent text-accent'
                : 'text-navLink border-transparent'
            }`}
            onClick={() => {
              handleTabs(1);
            }}
          >
            All
          </p>
          <p
            className={`baseText cursor-pointer capitalize border-b-[3px]   hover:text-accent ${
              tab == 2
                ? 'border-accent text-accent'
                : 'text-navLink border-transparent'
            }`}
            onClick={() => {
              handleTabs(2);
            }}
          >
            Read
          </p>
          <p
            className={`baseText cursor-pointer capitalize border-b-[3px]   hover:text-accent ${
              tab == 3
                ? 'border-accent text-accent'
                : 'text-navLink border-transparent'
            }`}
            onClick={() => {
              handleTabs(3);
            }}
          >
            Unread
          </p>
          <div className="relative inline-flex items-center gap-x-1  rounded-full p-1  cursor-pointer" onClick={() => {
            handleTabs(4);
          }}>
            <p
              className={`!font-medium baseText cursor-pointer capitalize border-b-[3px] hover:text-accent ${
                tab === 4
                  ? 'border-accent text-accent'
                  : 'text-navLink border-transparent'
              }`}
              onClick={() => {
                handleTabs(4);
              }}
            >
            Message{' '}
            </p>
           
            {chatCount > 0 ? (
              <span className="p-1 inline-flex justify-center items-center min-w-[18px] h-[18px] rounded-full bg-blue-400 text-white !font-semibold smallText">
                {chatCount}
              </span>
            ) : null}
          </div>
        </div>
        {notification ? (
          <>
            {tab === 1 && ( // For tab 1
              <>
                {notification?.allNotification?.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  place-content-center gap-4 md:gap-6 xl:gap-8 hid lg:p-5">
                    {allMsgBlock}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center w-full p-10">
                    There are no notifications for me to display at the moment.
                  </p>
                )}
              </>
            )}

            {tab === 2 && ( // For tab 2
              <>
                {notification?.readNotification?.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  place-content-center gap-4 md:gap-6 xl:gap-8 hid lg:p-5">
                    {readMsgBlock}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center w-full p-10">
                    There are no notifications for me to display at the moment.
                  </p>
                )}
              </>
            )}

            {tab === 3 && ( // For tab 3
              <>
                {notification?.unReadNotification?.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  place-content-center gap-4 md:gap-6 xl:gap-8 hid lg:p-5">
                    {unreadMsgBlock}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center w-full p-10">
                    There are no notifications for me to display at the moment.
                  </p>
                )}
              </>
            )}
            {tab === 4 && (
              <div>
                <WeavyNotification />
              </div>
            )}
          </>
        ) : (
          <div className="w-full flex item-center justify-center mt-5">
            <EditProfileLoader />
          </div>
        )}
      </div>
      {viewRequestBlockfull && (
        <ViewDetails
          closeFunction={handelViewCreateRequestBlockfull}
          currentdata={reqId}
          profileData={profileData}
        />
      )}
    </AdminLayout>
  );
}

export default NotificationOdds;
