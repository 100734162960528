import React, { useEffect, useState } from "react";

import { updateNotificationReadApi } from "../../apis";

function MessageCard({
  message,
  scanMessage,
  time,
  fromDate,
  data,
  viewFunction,
  setcurrentdata,
  notificationfun,
}) {
  // console.log(data);
  //  console.log(data);
  useEffect(() => {
    console.log("consultation id", data?.ConsultationId);
    setcurrentdata(data?.ConsultationId);
  }, [data]);
  function getInitials(name) {
    if (!name) {
      return null;
    }

    const nameArray = name.split(" ");
    return nameArray
      .map((word) => word.slice(0, 2))
      .join("")
      .toUpperCase();
  }

  function getBackgroundColor(name) {
    // Generate a background color based on the hash of the name
    const hashCode = name
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const colors = ["#f6ad55", "#f687b3", "#68d391", "#63b3ed", "#a0aec0"];
    return colors[hashCode % colors.length];
  }

  function Avatar({ name }) {
    const initials = getInitials(name);
    const bgColor = getBackgroundColor(name);

    return (
      <div
        className="w-10 h-10 rounded-full flex justify-center items-center"
        style={{ backgroundColor: bgColor }}
      >
        <span className="text-white font-semibold text-xs">{initials}</span>
      </div>
    );
  }
  const updateread = async () => {
    try {
      const res = await updateNotificationReadApi({
        _id: data?._id,
        isRead: true,
      });
      if (res.status === 200) {
        console.log(res.data);
        notificationfun();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="messageDiv p-3 border-b border-gray-200 bg-white cursor-pointer">
      <div
        className="flex items-center gap-4 relative"
        onClick={() => {
          updateread();
          viewFunction(data?.ConsultationId);
        }}
      >
        {data?.doctorDetails?.coverPic ? (
          <img
            src={data?.doctorDetails?.coverPic}
            className="size-10 min-w-10 rounded-full bg-gray-200 object-cover"
          />
        ) : (
          <Avatar name={data?.doctorDetails?.DoctorName} />
        )}
        <div className="space-y-1">
          <p className="baseText text-coalBlack capitalize">{data?.message}</p>
          {/* <div
            className={`gap-2 ${scanMessage != '' ? 'inline-flex' : 'hidden'}`}
          >
            <FaFileMedical className="text-base text-red-500 mt-0.5" />
            <p className="text-sm font-medium text-coalBlack">scan mesage</p>
          </div> */}
          {/* <FromDateNotification fromDate={fromDate} time={time} /> */}
        </div>
        {!data?.isRead && (
          <div className="w-3 h-3 rounded-full bg-blue-300 border-2 border-blue-600 absolute -top-2 -left-2"></div>
        )}
      </div>
    </div>
  );
}

function FromDateNotification({ fromDate, time }) {
  return (
    <p className="text-sm text-gray-300 font-normal">
      <span className="capitalize">{fromDate}</span> at {time}{" "}
    </p>
  );
}

export default MessageCard;
