import React from 'react';

function AmountDue() {
  return (
    <div className="p-4 pt-0 ">
      <div className="bg-gray-100 rounded-xl border border-gray-100 p-1.5">
        <p className='baseText text-coalBlack'>
          confirmation popup should be displayed along with the cost and 'you
          will be charged excess amount' should be displayed in notes
        </p>
      </div>
    </div>
  );
}

export default AmountDue;
