import axios from 'axios';

export const getHqUsersFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/hqusers/documents/search?q=*&query_by=&max_hits=all&per_page=250',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getBriefsFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/briefsv2/documents/search?q=*&query_by=&max_hits=all&per_page=250',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getProjectsFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/campaigns/documents/search?X-TYPESENSE-API-KEY=SGdIWdineRbSHFAxghr5FXFxuvfVC5kT&q=*&query_by',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getMashersFromTypesense = async () => {
  return await axios({
    method: 'get',
    baseURL: `${process.env.REACT_APP_HOST_NAME}`,
    url: '/collections/influencer/documents/search?q=*&query_by=',
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
export const getCompaniesFromTypesense = async (id, queryBy) => {
  return await axios({
    method: 'get',
    baseURL: `${process.env.REACT_APP_HOST_NAME}`,
    url: `/collections/companies/documents/search?q=${id || '*'}&query_by=${queryBy || ''}&max_hits=all`,
    headers: {
      'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY,
    }
  });
};
