import React, { useContext, useState } from 'react';
import { ProfileSliderContext } from '../../appContext';

function OverlayBlock({ children }) {
  const [slider] = useContext(ProfileSliderContext);

  return (
    <div
      className={`absolute top-0 left-0 z-40 bg-[#FAFAFA] col-span-2 p-6  min-h-screen h-full w-full  transition-transform ease-linear duration-500 lg:translate-x-0 lg:w-auto lg:border-l-2 border-gray-300 lg:static lg:p-10 lg:z-0 ${
        slider ? 'translate-x-0' : 'translate-x-[1300px] duration-500 '
      }pb-16 md:pb-6 `}
    >
      {children}
    </div>
  );
}

export default OverlayBlock;
