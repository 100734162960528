import React, { useContext, useEffect, useState } from 'react';
import CompletedConsultation from '../../components/MessageNotification/CompletedConsultation';
import NoShowConsultation from '../../components/MessageNotification/NoShowConsultation';
import ViewDetails from '../../components/ConsultationCard/ViewDetails';
import { getRecentMonthlyConsultations } from '../../apis';
import { AppContext } from '../../appContext';

function MonthlyEarnings({ data }) {
  const { userDetails } = useContext(AppContext);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  function handelViewRequestBlock(id) {
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata id', id);
    setCurrentRequest(id);
  }

  return (
    <>
      {data?.map((data) =>
        data?.status == 'completed' ? (
          <CompletedConsultation
            data={data}
            onclick={() =>
              handelViewRequestBlock(data?.transactions[0]?.consultation_id)
            }
          />
        ) : data?.status == 'no-show' ? (
          <NoShowConsultation
            data={data}
            onclick={() =>
              handelViewRequestBlock(data?.transactions[0]?.consultation_id)
            }
          />
        ) : null
      )}

      {ViewRequestBlock && (
        <ViewDetails
          closeFunction={handelViewRequestBlock}
          currentdata={currentRequest}
          // currentdata={'66c2db326492ae3dd38bac5b'}
          // profileData={profileData}
        />
      )}
    </>
  );
}

export default MonthlyEarnings;
