import React, { useContext, useEffect, useState } from 'react';
import { BsCurrencyRupee } from 'react-icons/bs';
import { TfiClose } from 'react-icons/tfi';
import { AppContext, WalletContext } from '../../appContext';
import { getWithdrawHistory } from '../../apis';
import moment from 'moment';

function WithdrawRequest() {
  const [
    handelWithdrawBlock,
    doctorProfile,
    setUpdatePage,
    updatePage,
  ] = useContext(WalletContext);

  const [withdrawHistoryData, setWithdrawHistoryData] = useState([]);
  const { userDetails } = useContext(AppContext);
  const toGetWithdrawHistory = async (id) => {
    try {
      const res = await getWithdrawHistory(id);
      if (res.status === 200) {
        const array = res?.data?.WithdrawHistory?.filter((data) => {
          if (data?.status != 'Paid') {
            return data;
          }
        });
        console.log(array);

        setWithdrawHistoryData(array.reverse());
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    toGetWithdrawHistory(userDetails?.DoctorProfile?._id);
  }, [userDetails, updatePage]);

  return (
    <>
      {withdrawHistoryData.length > 0 ? (
        <section className="bg-green-50  rounded-2xl  flex flex-col border border-gray-200 h-fit">
          <header className="  p-4 pb-2 border-b border-gray-200">
            <h1 className="text-coalBlack subHeadingText ">
              Most Recent Withdraw Request
            </h1>
          </header>
          {/* {withdrawHistoryData?.map((data) => (
            
          ))} */}
          <div className="p-4 border-b border-green-200">
            <p className="baseText text-coalBlack">
              Requested <BsCurrencyRupee className="inline-block" />
              {withdrawHistoryData[0]?.withdrawAmt} on{' '}
              {moment(withdrawHistoryData[0]?.createdAt)
                .format('ddd DD MMM YYYY')
                .toUpperCase()}
            </p>
            <span className="flex items-center gap-x-1 mt-1 baseText text-coalBlack">
              Status:{' '}
              <span className="text-center capitalize smallText rounded p-0.5 px-1 inline-block text-blue-600 bg-blue-200 ">
                pending
              </span>
            </span>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default WithdrawRequest;
