import { useFormik } from 'formik';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { editUserProfile } from '../../components/Validation';
import { EditProfileLoader } from '../../components/common/loader';
import { InputErrorMessage } from '../../components/errorMessages';
import { FaPencil } from 'react-icons/fa6';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { CgCalendarDates } from 'react-icons/cg';
import toast from 'react-hot-toast';
import { MdPhotoCamera } from 'react-icons/md';
import ProfileTopNavigation from './ProfileTopNavigation';
import { UpdateUserDetails, fileUploadApi, getUserApi } from '../../apis';
import { AppContext } from '../../appContext';
import { useDropzone } from 'react-dropzone';
import ProfileDefault from '../../Assets/img/logos/place_profile.png';

function PersonalDetails() {
  const [profileData, setprofileData] = useState('');
  const { getUserDetails } = useContext(AppContext);
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setprofileData(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: {
      userEmail: profileData?.email || '',
      contactNumber: profileData?.DoctorProfile?.phoneNo || '',
      profile: profileData?.DoctorProfile?.coverPic || '',
    },
    enableReinitialize: true,
    validationSchema: editUserProfile,
    onSubmit: async (values) => {
      console.log(values);
      const { userEmail, contactNumber, profile } = values;

      const payloadFormate = {
        profileId: profileData.DoctorProfile?._id,
        email: userEmail,
        phoneNo: contactNumber,
        coverPic: profile,
      };
      console.log(payloadFormate);
      try {
        const res = await UpdateUserDetails(payloadFormate);
        if (res.status === 200) {
          toast.success('Profile updated successfully');
          getUserDetails();
        }
      } catch (error) {
        console.log(error.response.data.error);
        const message = error.response.data.error || error.response.statusText;
        toast.error(message, { id: 'error' });
      }
    },
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const onDropProfile = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await profilefileUpload(file);
    }
  }, []);
  const profileonUploadProgress = (progressEvent, file) => {
    console.log(progressEvent);
  };
  const [isprofile, setisprofile] = useState(false);
  const profilefileUpload = async (file) => {
    try {
      setisprofile(true);
      const formData = new FormData();
      formData.append('file', file);
      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        profileonUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // Here we update only the profile field
        setisprofile(false);
        formik.setFieldValue('profile', uploadResponse.data.filePath);
      } else {
        setisprofile(false);
        toast.error('image upload failed', { is: 'err' });
      }
    } catch (error) {
      setisprofile(false);
      console.error('An error occurred:', error);
      toast.error('image upload failed', { is: 'err' });
    } finally {
      setisprofile(false);
    }
  };

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
    isDragActive: isDragActiveProfile,
  } = useDropzone({
    onDrop: onDropProfile,
  });
  return (
    <div className="text-coalBlack">
      <ProfileTopNavigation pageTitle={'Personal Details'} />
      <section className="my-7 max-w-md me-auto px-4">
        <div className="profileImage">
          {formik.values.profile ? (
            <div
              className="t size-16 rounded-full ms-10 relative bg-cover flex items-center justify-center bg-center"
              style={
                isprofile
                  ? { backgroundColor: '#efeded' }
                  : { backgroundImage: `url(${formik.values.profile})` }
              }
              {...getRootPropsProfile()}
            >
              {!isprofile && (
                <img
                  src={formik.values.profile}
                  className="size-16 rounded-full object-cover"
                />
              )}
              {isprofile && <EditProfileLoader />}
              <div className="bg-black/25 p-1.5 rounded-full  absolute bottom-0 -right-2">
                <MdPhotoCamera className="text-white  text-base" />
              </div>
            </div>
          ) : (
            <div
              className="f size-16 bg-white border border-slate-100 rounded-full ms-10 relative bg-cover"
              {...getRootPropsProfile()}
              style={{ backgroundImage: `url(${ProfileDefault})` }}
            >
              {isprofile && <EditProfileLoader />}
              <div className="bg-black/25 p-1.5 rounded-full  absolute bottom-0 -right-2">
                <MdPhotoCamera className="text-white  text-base" />
              </div>
            </div>
          )}

          <input {...getInputPropsProfile()} />
          <p className="my-1 py-1 smallText text-navLink">
            {formik.values.profile ? 'Edit' : 'Upload'} your profile image
          </p>
        </div>
        <form className="mt-11 space-y-4">
          <h2 className="baseText !font-medium">Personal information</h2>
          <div className="userEmail">
            <p className="baseText text-coalBlack mb-2 capitalize">Email</p>
            <div
              className={
                Boolean(formik.touched.userEmail) &&
                Boolean(formik.errors.userEmail)
                  ? 'form-control overflow-hidden line-clamp-1 border-danger transparent-input-border flex items-center'
                  : 'form-control overflow-hidden line-clamp-1 transparent-input-border flex items-center'
              }
            >
              <div style={{ width: '95%' }}>
                <input
                  type="text"
                  name="userEmail"
                  style={{ background: 'transparent', width: '100%' }}
                  id="userEmail"
                  placeholder="Enter name :"
                  value={formik.values.userEmail}
                  title={formik.values.userEmail}
                  onChange={handleChange}
                />
              </div>
              <div style={{ width: '5%' }} className="p-1">
                <FaPencil />
              </div>
            </div>
            <InputErrorMessage
              error={formik.touched.userEmail && formik.errors.userEmail}
            />
          </div>
          <div className="contactNumber">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Mobile Number
            </p>
            <div className="relative">
              <input
                type="text"
                name="contactNumber"
                className={
                  Boolean(formik.touched.contactNumber) &&
                  Boolean(formik.errors.contactNumber)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="contactNumber"
                placeholder="Enter mobile no :"
                value={formik.values.contactNumber}
                onChange={handleChange}
              />
              <FaPencil className="absolute top-1/2 -translate-y-1/2 right-4 text-gray-400" />
            </div>
            <InputErrorMessage
              error={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
            />
          </div>
          {/* <div className="dateOfBirth">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Date Of Birth
            </p>
            <div className="relative">
              <input
                type="text"
                name="dateOfBirth"
                className={
                  Boolean(formik.touched.dateOfBirth) &&
                  Boolean(formik.errors.dateOfBirth)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="dateOfBirth"
                placeholder="DD/MM/YYYY :"
                value={formik.values.dateOfBirth}
                onChange={handleChange}
              />
              <CgCalendarDates className="absolute top-1/2 -translate-y-1/2 right-4 text-gray-400" />
            </div>
            <InputErrorMessage
              error={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            />
          </div>
          <div className="location">
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Location
            </p>
            <div className="relative">
              <input
                type="text"
                name="location"
                className={
                  Boolean(formik.touched.location) &&
                  Boolean(formik.errors.location)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="location"
                placeholder="Add location :"
                value={formik.values.location}
                onChange={handleChange}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.location && formik.errors.location}
            />
          </div> */}
          <SimpleButton
            customClass={'bg-accent text-white rounded-lg w-full'}
            buttonType={'primary'}
            title={'Update'}
            onClickEvent={formik.handleSubmit}
          />
        </form>
      </section>
    </div>
  );
}

export default PersonalDetails;
