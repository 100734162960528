import { useFormik } from 'formik';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import {
  changePassword,
  createUser,
  forgotPassword,
  getProfileById,
  loginApi,
  otpVerify,
  userAuth,
} from '../../apis/index.js';
import {
  ForgotPasswordEmailValidation,
  ResetPasswordValidation,
  
} from '../../components/Validation/index.js';

import { InputErrorMessage } from '../../components/errorMessages/index.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import MobileModel from '../../components/Modals/MobileModel.js';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import OtpInput from 'react-otp-input';
import Countdown from '../../components/Countdown/Countdown.js';

function ForgotPasswordScreens({ handelForgotPasswordModal }) {
  const [passwordType, setPasswordType] = useState('password');
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [forgotPasswordBlock, setForgotPasswordBlock] = useState(1);
  const [otp, setOTP] = useState('');
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [doctorDetails, setDoctorDetails] = useState([]);
  function handelForgotPasswordBlocks(prop) {
    setForgotPasswordBlock(prop);
  }
  const onForgotPassword = async (payload) => {
    try {
      setLoader(true);
      const response = await forgotPassword(payload);
      if (response.status === 200) {
        setLoader(false);
        setEmail(payload.email);
        toast.success('otp sent successfully');
        handelForgotPasswordBlocks(2);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.status == 404) {
        toast.error(error.response.data.message);
      }
      setLoader(false);
    }
  };
  
  const onVerifyOtp = async (values) => {
    values.preventDefault();
    try {
      setLoader(true);
      if (otp.length < 4) {
        setLoader(false);
        setIsWrongOtp(true);
        setErrorMsg('Please enter 4 digits otp');
      } else {
        values.OTP = {
          email: email,
          otp: otp,
        };
        const response = await otpVerify(values.OTP);
        console.log(response);
        if (response.status === 200) {
          toast.success('otp verified successfully');
          setDoctorDetails(response.data.user);
          handelForgotPasswordBlocks(3);
          setLoader(false);
        } else {
          setIsWrongOtp(true);
          setErrorMsg('Wrong OTP');
        }
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error('Invalid otp');
    }
  };
  const onChangeNewPassword = async (payload) => {
    try {
      setLoader(true);
      const response = await changePassword(payload);
      if (response.status === 200) {
        setLoader(false);
        passwordUpdated();
        toast.success('Password Updated successfully');
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.status == 404) {
        toast.error(error.response.data.message);
      }
      setLoader(false);
    }
  };
  const resendOtp = async () => {
    try {
      setLoader(true);
      const payload = {
        email: email,
      };
      const response = await forgotPassword(payload);
      if (response.status === 200) {
        setLoader(false);
        toast.success('otp sent successfully');
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.status == 404) {
        toast.error(error.response.data.message);
      }
      setLoader(false);
    }
  };
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: ForgotPasswordEmailValidation,
    onSubmit: async (values) => {
      const { email } = values;
      onForgotPassword(values);
    },
  });
  const formik3 = useFormik({
    initialValues: { newpassword: '', confirmpassword: '' },
    validationSchema: ResetPasswordValidation,
    onSubmit: async (values) => {
      const { newpassword, confirmpassword } = values;
      const payloadFormate = {
        userId: doctorDetails._id,
        password: newpassword,
      };
      onChangeNewPassword(payloadFormate);
    },
  });
  
  const handleOtpInput = (otp) => {
    setIsWrongOtp(false);
    setErrorMsg('');
    setOTP(otp);
  };
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const handleChange3 = (e) => {
    e.preventDefault();
    formik3.handleChange(e);
  };
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  function handelConfirmPasswordType() {
    if (confirmPasswordType == 'text') {
      setConfirmPasswordType('password');
    } else if (confirmPasswordType == 'password') {
      setConfirmPasswordType('text');
    }
  }
  function passwordUpdated() {
    setForgotPasswordBlock(1);
    handelForgotPasswordModal();
  }
  
  const forgotPasswordBlockHeader = (
    <>
      <h2 className="font-medium text-2xl ">Forgot password</h2>
      <p className="text-sm text-primary_v2 mt-4">
          Enter your email for the verification process, we will send 4 digits
          code to your email.
      </p>
    </>
  );
  const forgotPasswordBlockBody = (
    <>
      <form className="forgotPassword" onSubmit={formik.handleSubmit}>
        <div className="modelBody my-2 py-2 ">
          <div>
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
                Email
            </p>
            <input
              type="email"
              name="email"
              className={
                Boolean(formik.touched.email) && Boolean(formik.errors.email)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="emailll"
              placeholder="Email"
              value={formik.values.email}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.email && formik.errors.email}
            />
          </div>
        </div>
        <div className="modelFooter">
          <div className="mt-6">
            <button
              className="btn btn_primary_black text-white w-full rounded-xl"
              type="submit"
            >
              {!loader ? (
                'Continue'
              ) : (
                <div className="text-xs">
                  <EditProfileLoader />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
  const verificationBlockHeader = (
    <>
      <h2 className="font-medium text-2xl ">Enter verification code</h2>
      <p className="text-sm text-primary_v2 mt-4">
          Enter the 4 digits code that you received on your email.
      </p>
    </>
  );
  const verificationBlockBody = (
    <form className="verifyotp" onSubmit={onVerifyOtp}>
      <div className="modelBody my-2 py-2">
        <div className=" otpBox ">
          <OtpInput
            inputStyle={{
              marginLeft: '0',
              width: '3.375rem',
              height: '3.375rem',
              borderRadius: '0.313rem',
              textAlign: 'center',
              border: '1px solid rgba(0,0,0,0.3)',
            }}
            className={'me-2 text-center fs-3  w-50 otpInputt'}
            errorStyle={{ border: 'solid 1px red' }}
            hasErrored={isWrongOtp}
            isInputNum
            value={otp}
            onChange={(otp) => handleOtpInput(otp)}
            numInputs={4}
            separator={<span> </span>}
          />
        </div>
      </div>
      <div className="modelFooter">
        <p>
          <Countdown initialSeconds={60} resendOtp={resendOtp}/>
        </p>
        <div className="mt-6">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Continue'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );
  const resetBlockHeader = (
    <>
      <h2 className="font-medium text-2xl ">Reset password</h2>
      <p className="text-sm text-primary_v2 mt-4">
          Set the new password for your account so you can login and access all
          the features.
      </p>
    </>
  );
  const resetBlockBody = (
    <form className="resetpassword" onSubmit={formik3.handleSubmit}>
      <div className="modelBody my-2 py-2 space-y-4">
        <div className="newPasswordField">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
              New password
          </p>
          <div className="relative">
            <input
              type={passwordType}
              name="newpassword"
              className={
                Boolean(formik3.touched.newpassword) &&
                  Boolean(formik3.errors.newpassword)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="newpassword"
              placeholder="New Password "
              value={formik3.values.newpassword}
              onChange={handleChange3}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelPasswordType}
            >
              {passwordType == 'password' ? (
                <IoEyeOff />
              ) : passwordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={formik3.touched.newpassword && formik3.errors.newpassword}
          />
        </div>
        <div className="confirmPasswordField">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
              Confirm password
          </p>
          <div className="relative">
            <input
              type={confirmPasswordType}
              name="confirmpassword"
              className={
                Boolean(formik3.touched.confirmpassword) &&
                  Boolean(formik3.errors.confirmpassword)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="confirmpassword"
              placeholder="confirm Password "
              value={formik3.values.confirmpassword}
              onChange={handleChange3}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelConfirmPasswordType}
            >
              {confirmPasswordType == 'password' ? (
                <IoEyeOff />
              ) : confirmPasswordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={
              formik3.touched.confirmpassword && formik3.errors.confirmpassword
            }
          />
        </div>
      </div>
      <div className="modelFooter">
        <div className="mt-6">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
            // onClick={passwordUpdated}
          >
            {!loader ? (
              'Update Password'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );
  return (
    <>
      {forgotPasswordBlock == 1 ? (
        <>
          <div className="block lg:hidden">
            <MobileModel
              MobileModelHeader={forgotPasswordBlockHeader}
              MobileModelBody={forgotPasswordBlockBody}
              popupCloseFunction={handelForgotPasswordModal}
            />
          </div>
          <div className="hidden lg:flex flex-col justify-center absolute top-0 left-0 z-10 w-full h-full bg-white">
            <div className="max-w-sm w-full m-auto">
              <div
                className="mb-8 flex gap-1 items-center text-primary_v2 cursor-pointer"
                onClick={passwordUpdated}
              >
                <HiOutlineArrowSmLeft className="text-xl" />
                <p>Back to sign in</p>
              </div>
              {forgotPasswordBlockHeader}
              {forgotPasswordBlockBody}
            </div>
          </div>
        </>
      ) : forgotPasswordBlock == 2 ? (
        <div>
          <div className="block lg:hidden">
            <MobileModel
              MobileModelHeader={verificationBlockHeader}
              MobileModelBody={verificationBlockBody}
              popupCloseFunction={handelForgotPasswordModal}
            />
          </div>
          <div className="hidden lg:flex flex-col justify-center absolute top-0 left-0 z-10 w-full h-full bg-white">
            <div className="max-w-sm w-full m-auto">
              <div className="mb-8 flex gap-1 items-center text-primary_v2 cursor-pointer"  onClick={passwordUpdated}>
                <HiOutlineArrowSmLeft className="text-xl" />
                <p>Back to sign in</p>
              </div>
              {verificationBlockHeader}
              {verificationBlockBody}
            </div>
          </div>
        </div>
      ) : forgotPasswordBlock == 3 ? (
        <div>
          <div className="block lg:hidden">
            <MobileModel
              MobileModelHeader={resetBlockHeader}
              MobileModelBody={resetBlockBody}
              popupCloseFunction={handelForgotPasswordModal}
            />
          </div>
          <div className="hidden lg:flex flex-col justify-center absolute top-0 left-0 z-10 w-full h-full bg-white">
            <div className="max-w-sm w-full m-auto">
              <div
                className="mb-8 flex gap-1 items-center text-primary_v2 cursor-pointer"
                onClick={passwordUpdated}
              >
                <HiOutlineArrowSmLeft className="text-xl" />
                <p>Back to sign in</p>
              </div>
              {resetBlockHeader}
              {resetBlockBody}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ForgotPasswordScreens;