import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';
import LoaderCard from '../ConsultationCard/LoaderCard';
import { getWidthdrawaHistory } from '../../apis';

const BalanceHistoryChart = ({ doctorProfile }) => {
  const [balanceData, setbalanceData] = useState(null);
  const [loader, setLoader] = useState(true);
  const getItem = async () => {
    try {
      const res = await getWidthdrawaHistory(doctorProfile?.DoctorProfile?._id);
      if (res.status === 200) {
        console.log(res?.data, 'Withdraw History Graph');
        setbalanceData(res?.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (doctorProfile?.DoctorProfile?._id) {
      getItem();
    }
  }, [doctorProfile]);
  return loader ? (
    <div className="cardLoader h-48 lg:h-96"></div>
  ) : (
    <HistoryChart data={balanceData} />
  );
};

export default BalanceHistoryChart;

function HistoryChart({ data }) {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  // Prepare the labels and data for the chart
  const labels = data?.map((item) => dayjs(item.paidDate).format('MMMM'));
  const withdrawAmounts = data?.map((item) => item.withdrawAmt);

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const newChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Withdraw History',
            data: withdrawAmounts,
            fill: false,
            borderColor: 'rgb(22, 72, 206)',
            tension: 0.4,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month',
            },
            grid: {
              drawBorder: false,
              drawOnChartArea: false, // Remove vertical grid lines
            },
          },
          y: {
            title: {
              display: true,
              text: 'Amount',
            },
            beginAtZero: true,
            grid: {
              drawBorder: false,
              drawOnChartArea: false, // Remove horizontal grid lines
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
        },
      },
    });

    setChart(newChart);

    // Cleanup function to destroy chart on unmount
    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [data]); // Add data as a dependency to useEffect

  return (
    <div>
      <div className="flex items-center px-2 mb-2 lg:pt-3">
        <h1 className="text-coalBlack subHeadingText">Withdraw History</h1>
      </div>
      <canvas ref={chartRef}></canvas>
    </div>
  );
}
