import React from 'react';

function MainOddsPopup({ children, closeFunction }) {
  return (
    <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[999]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-inherit"
        onClick={closeFunction}
      ></div>
      <div className="bg-white relative z-20 m-auto overflow-y-auto scrollbar  max-h-screen w-full lg:w-auto lg:max-w-4xl xl:max-w-5xl lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl ">
        {children}
      </div>
    </section>
  );
}

export default MainOddsPopup;
