import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import OddsChat from './component/OddsChat';
import MainOddsPopup from '../oddsPopup/MainOddsPopup';
function HospitalChat({ closeFunction, doctorDetails, consultationId }) {
  return (
    <>
      <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-[10000] flex justify-center items-center  ">
        <div
          className="absolute top-0 left-0 w-full h-full bg-inherit"
          onClick={closeFunction}
        ></div>
        <div className="bg-white relative z-20 m-auto overflow-y-auto scrollbar overscroll-contain h-full   max-h-screen w-full lg:w-auto lg:max-w-4xl xl:max-w-5xl  lg:max-h-[96vh]  lg:rounded-3xl ">
          <div className="bg-white lg:min-w-[896px] xl:min-w-[1020px] h-dvh min-h-dvh">
            <header className="headingText p-4 bg-white z-10  absolute top-0 left-0 w-full">
              {' '}
              <FiChevronLeft
                className="text-2xl text-coalBlack size-9 me-1 p-1 rounded-full active:bg-sky-50 inline-block"
                onClick={closeFunction}
              />
              <span className="subHeadingText text-coalBlack mt-px">Back</span>
            </header>
            <div className="custom-chat-container">
              <OddsChat data={doctorDetails} consultationId={consultationId} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HospitalChat;
