import React, { useContext, useEffect } from 'react';
import {
  useWeavy,
  WyChat,
  WyNotificationToasts,
  Weavy,
  WyNotifications,
} from '@weavy/uikit-react';
import { getOddsWeavyTokenApi } from '../../../apis';
import { AppContext } from '../../../appContext';
function WeavyNotification() {
  const { userDetails } = useContext(AppContext);

  // const getWeavyToken = async (user) => {
  //   try {
  //     const res = await getOddsWeavyTokenApi(user);
  //     if (res.status === 200 || res.status === 201) {
  //       return res.data.token;
  //     }
  //   } catch (err) {
  //     console.error('Error:', err);
  //     throw err;
  //   }
  // };

  // useEffect(() => {
  //   const initializeChat = async () => {
  //     try {
  //       const hospitalDetails = {
  //         userId: userDetails?.DoctorProfile?._id,
  //         username: `Dr.${userDetails?.DoctorProfile?.DoctorName}`,
  //       };
  //       const isHospitalToken = await getWeavyToken(hospitalDetails);
  //       const weavy = new Weavy();
  //       weavy.url = process.env.REACT_APP_WEAVY_URL;
  //       weavy.tokenFactory = async (refresh) => isHospitalToken;
  //       // weavyInstanceRef.current = weavy;
  //     } catch (err) {
  //       console.error('Error initializing chat:', err);
  //     }
  //   };

  //   initializeChat();

 
  // }, [ userDetails]);
  return (
    <div>
      <WyNotifications ></WyNotifications>
    </div>
  );
}

export default WeavyNotification;