import React, {useState} from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import expandView from '../Icons/expandView.svg';
import minimiseView from '../Icons/minimiseView.svg';
import MashCard from '../components/cards/MashCard';
import { XCircleFill,X } from 'react-bootstrap-icons';

export default function ProfilePreview({ data }) {
  const { REACT_APP_PROFILEPREVIEWIFRAME } = process.env;
 
  //  alert(JSON.stringify(data))
  const hitTest={
    Name:data.Name,
    HandleID:data.HandleID,
    Photo:data.Photo,
    posts:data.posts,
    Followers:data.Followers,
    Following:data.Following,

  }
  return (
    <>
      <form>
        <div
          className="modal"
          id="profilePreview"
          tabIndex={-1}
          aria-labelledby="profilePreview"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" style={{maxWidth: '400px' }}>
            <div className="modal-content shadow-3">
              <div className="modal-body w-full" style={{padding:'0px'}}>
              <div className="cursor-pointer modalclose" data-bs-dismiss="modal" aria-label="Close">
                 <X color='#fff' size={29} fontWeight={'bold'} />
              </div>
                <div className='flex justify-center items-center'>
                <MashCard hit={hitTest}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}