import React, { useContext, useEffect, useRef, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserProfile from '../../components/UserProfile/UserProfile';
import { FiLogOut } from 'react-icons/fi';
import { GoArrowUpRight } from 'react-icons/go';
import SideMessageNotification from './SideMessageNotification';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { Link } from 'react-router-dom';
import ViewRequest from '../../components/ConsultationCard/Viewrequest';
import {
  getDashboardApi,
  getDoctorApibyUserID,
  getNotificationAPI,
  getProfileById,
  getRecentRequest,
  getUserApi,
} from '../../apis';
import LoaderCard from '../../components/ConsultationCard/LoaderCard';
import { AppContext } from '../../appContext';
import ConsultationHistory from './ConsultationHistory';
import BookingsCard from './BookingsCard';
import MonthlyCredits from './MonthlyCredits';
import ViewDetails from '../../components/ConsultationCard/ViewDetails';

function DashboardOdds() {
  const [viewRequestBlock, setViewRequestBlock] = useState(false);
  const [loader, setLoader] = useState(true);
  const [recentRequest, setRecentRequest] = useState([]);
  const [rerenderComponent, setRerenderComponent] = useState(false);
  // const [userDetails , setUserDetails] = useState();
  const [reqId, setReqId] = useState();
  const [reload, setReload] = useState(true);
  let consultationBlock;
  const { userDetails } = useContext(AppContext);
  console.log(userDetails);
  const [currentdata, setcurrentdata] = useState(null);
  const [monthlydata, setmonthlydata] = useState([]);
  const [monthlylable, setmonthlylable] = useState([]);
  const [totalcreditused, settotalcreditused] = useState(0);
  const [inpersoncount, setinpersoncount] = useState(0);
  const [virtualcount, setvirtualcount] = useState(0);
  const [consultationhistorylable, setconsultationhistorylable] = useState([]);
  const [consultationhistoryremote, setconsultationhistoryremote] = useState(
    []
  );
  const [
    consultationhistoryinperson,
    setconsultationhistoryinperson,
  ] = useState([]);
  const [consultationhistorysucc, setconsultationhistorysucc] = useState([]);
  const [
    consultationhistoryremotecount,
    setconsultationhistoryremotecount,
  ] = useState(0);
  const [
    consultationhistoryinpersoncount,
    setconsultationhistoryinpersoncount,
  ] = useState(0);
  const [
    consultationhistorysucccount,
    setconsultationhistorysucccount,
  ] = useState(0);
  const [notificationFilter, setNotificationFilter] = useState('');

  const handleFilterChange = (event) => {
    setNotificationFilter(event.target.value);
    // Call getNotification function with the selected filter value
    getNotification(profileData?.DoctorProfile?._id, event.target.value);
  };
  function handelViewCreateRequestBlock(id) {
    setReqId(id);
    setViewRequestBlock(!viewRequestBlock);
  }
  if (loader) {
    consultationBlock = (
      <div className="space-y-3">
        <LoaderCard />
        <LoaderCard />
      </div>
    );
  } else {
    if (recentRequest && recentRequest.length > 0) {
      consultationBlock = recentRequest.map((data, i) => {
        // console.log('data,' , data);

        if (
          data?.Consultationstatus === 'pending' &&
          userDetails?.DoctorProfile?.specialization?.name ==
            data?.Specalaization?.name
        ) {
          return (
            <ConsultationCard
              consultationTitle={data.hospitalName}
              requestId={data.requestId}
              consultationType={data.consType}
              consultationRequest={data.priority}
              requestDescription={data.requestDescription}
              key={i}
              data={data}
              fillBtnTitle="View Details"
              unFillBtnTitle="Delete"
              fillBtnfun={() => {
                handelViewCreateRequestBlock(data?._id);
                setcurrentdata(data);
              }}
            />
          );
        }
        return null; // You need to return null for elements you don't want to render
      });
    } else {
      consultationBlock = (
        <p className="border bg-white rounded text-gray-400 text-center rounded-lg h-20 flex items-center justify-center">
          No requests found.
        </p>
      );
    }
  }
  const getAllRecentRequest = async (id) => {
    try {
      console.log('specal id', id);
      const res = await getRecentRequest(id);
      if (res.status === 200) {
        // console.log(res.data.consultationAllData);
        const consultationData = res?.data?.consultationAllData.filter(
          (data) => {
            return data?.Consultationstatus != 'accepted';
          }
        );
        setRecentRequest(consultationData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(true);
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log('profile id', res?.data?.Profile?._id);
        fetchDoctor(res?.data?.Profile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [profileData, setprofileData] = useState('');
  const fetchDoctor = async (id) => {
    try {
      const res = await getDoctorApibyUserID(id);
      if (res.status === 200) {
        console.log(res.data.Profile);
        setprofileData(res?.data?.Profile);
        Dashboard(res?.data?.Profile?.DoctorProfile?._id);
        getNotification(res?.data?.Profile?.DoctorProfile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [notification, setnotificaiton] = useState(null);
  const getNotification = async (userId, filterval) => {
    try {
      if (!filterval) {
        filterval = 'today';
      }
      console.log(userId);
      const res = await getNotificationAPI(userId, filterval);
      if (res.status === 200) {
        console.log(res.data);
        setnotificaiton(res.data.doctorNotification);
      }
    } catch (error) {
      //console.error(error);
    }
  };
  const Dashboard = async (id) => {
    try {
      const res = await getDashboardApi(id);
      if (res.status === 200) {
        console.log(res.data);
        if (res?.data?.consttypecount?.length > 0) {
          setinpersoncount(res?.data?.consttypecount?.[0]?.inperson);
          setvirtualcount(res?.data?.consttypecount?.[0]?.Virtual);
        }
        if (res?.data?.MonthlyEarning?.length > 0) {
          var date_ranges = await res?.data?.MonthlyEarning?.map(
            (item) => item.date_range
          );
          console.log(date_ranges);
          setmonthlylable(date_ranges);
          var totalDoctorFee = await res?.data?.MonthlyEarning?.map(
            (item) => item.DoctorFee
          );
          console.log(totalDoctorFee);
          setmonthlydata(totalDoctorFee);
          const totalDoctorFeetotal = await res?.data?.MonthlyEarning?.reduce(
            (acc, usage) => acc + usage.DoctorFee,
            0
          );
          settotalcreditused(totalDoctorFeetotal);
        }
        if (res?.data?.completedconstdata?.length > 0) {
          const date_ranges = res.data.completedconstdata.map((item) => {
            if (item.total.length > 0) {
              const totalItem = item.total[0]; // Assuming there's only one item in the total array
              return `${totalItem.monthName}`;
            }
          });
          console.log(date_ranges);
          setconsultationhistorylable(date_ranges);

          const inperson = res.data.completedconstdata.map((item) => {
            if (item.inperson.length > 0) {
              const inpersonItem = item.inperson[0]; // Assuming there's only one item in the inperson array
              return inpersonItem.count;
            }
          });
          console.log(inperson);
          setconsultationhistoryinperson(inperson);

          const virtual = res.data.completedconstdata.map((item) => {
            if (item.Virtual.length > 0) {
              const virtualItem = item.Virtual[0]; // Assuming there's only one item in the Virtual array
              return virtualItem.count;
            }
          });
          console.log(virtual);
          setconsultationhistoryremote(virtual);
          const total = res.data.completedconstdata.map((item) => {
            if (item.total.length > 0) {
              const totalItem = item.total[0]; // Assuming there's only one item in the total array
              return totalItem.count;
            }
          });
          console.log(total);
          setconsultationhistorysucc(total);
          const inpersonCountconst = res.data.completedconstdata.reduce(
            (acc, item) => {
              console.log(item.inperson.length);
              if (item.inperson.length > 0) {
                const inpersonItem = item.inperson[0]; // Assuming there's only one item in the inperson array
                return acc + inpersonItem.count;
              }
              return acc;
            },
            0
          );
          console.log(inpersonCountconst);
          setconsultationhistoryinpersoncount(inpersonCountconst);
          const virtualCountconst = res.data.completedconstdata.reduce(
            (acc, item) => {
              if (item.Virtual.length > 0) {
                const virtualItem = item.Virtual[0]; // Assuming there's only one item in the Virtual array
                return acc + virtualItem.count;
              }
              return acc;
            },
            0
          );
          console.log(virtualCountconst);
          setconsultationhistoryremotecount(virtualCountconst);
          const totalCount = res.data.completedconstdata.reduce((acc, item) => {
            if (item.total.length > 0) {
              const totalItem = item.total[0]; // Assuming there's only one item in the total array
              return acc + totalItem.count;
            }
            return acc;
          }, 0);
          console.log(totalCount);
          setconsultationhistorysucccount(totalCount);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  function onload() {
    // hiddenButtonRef.current.click();
    // location.reload();
    setReload(!reload);
  }
  useEffect(() => {
    fetchData();
    // getUserDetails(localStorage.getItem('userId'));
  }, []);
  useEffect(()=>{
    getAllRecentRequest(userDetails?.DoctorProfile?.specialization?._id);
  },[reload,userDetails]);
  function handelViewCreateRequestBlock(prop) {
    setReqId(prop);
    setViewRequestBlock(!viewRequestBlock);
  }
  const [viewRequestBlockfull, setViewRequestBlockfull] = useState(null);
  function handelViewCreateRequestBlockfull(prop) {
    setReqId(prop);
    setViewRequestBlockfull(!viewRequestBlockfull);
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <AdminLayout>
      <div className="p-4 lg:hidden sticky top-0 left-0 bg-white z-50">
        <UserProfile
          NotificationBlock={true}
          mailId={userDetails?.DoctorProfile?.DoctorName}
          imgUrl={userDetails?.DoctorProfile?.coverPic}
        />
      </div>

      <div className="hidden xl:block h-28 bg-blue-700 rounded-br-2xl rounded-bl-2xl relative">
        <img
          className="w-full h-full object-cover rounded-br-2xl rounded-bl-2xl"
          src={require('../../Assets/img/odds/Graphic Side.jpg')}
        />
        <h1 className="text-white highlightText absolute top-1/2 left-6 -translate-y-1/2 capitalize">
          Welcome, Dr. {userDetails?.DoctorProfile?.DoctorName}
        </h1>
      </div>
      <main>
        {/* Dashboard sm screen start */}
        <div className="md:hidden p-4 mb-2 dashboardSmDevices">
          <Slider {...settings}>
            <>
              <BookingsCard
                imgUrl={require('../../Assets/img/odds/calender.png')}
                bookingTitle={'In-person Appointments'}
                bookingCount={inpersoncount}
                bookingPercentage={'4.8%'}
                bookingFromDate={'from last week'}
              />
              <div className="mt-7">
                <BookingsCard
                  imgUrl={require('../../Assets/img/odds/virtualbookings.png')}
                  bookingTitle={'Virtual Appointments'}
                  bookingCount={virtualcount}
                  bookingPercentage={'10.8%'}
                  bookingFromDate={'from last week'}
                />
              </div>
            </>

            <>
              <MonthlyCredits
                monthlydata={monthlydata}
                monthlylable={monthlylable}
                totalCreditCount={totalcreditused}
              />
            </>
            <>
              <ConsultationHistory
                consultationhistorylable={consultationhistorylable}
                consultationhistoryremote={consultationhistoryremote}
                consultationhistoryinperson={consultationhistoryinperson}
                consultationhistorysucc={consultationhistorysucc}
                consultationhistoryremotecount={consultationhistoryremotecount}
                consultationhistoryinpersoncount={
                  consultationhistoryinpersoncount
                }
                consultationhistorysucccount={consultationhistorysucccount}
              />
            </>
          </Slider>
          <section className="mt-14 mb-14">
            <div className="flex justify-between items-center mb-4">
              <p className="text-coalBlack subHeadingText capitalize">
                Request History
              </p>
              <Link to={'/requestbroadcast'}>
                <p className="capitalize smallText text-navLink">see all</p>
              </Link>
            </div>

            <div className="space-y-3">{consultationBlock}</div>
          </section>
        </div>
        {/* Dashboard sm screen end */}
        {/* Dashboard large screen start */}
        <div className="mt-6 p-4 hidden md:block">
          <div className="grid grid-cols-12 gap-6  py-4 xl:px-8 xl:pb-0">
            <div className="col-span-12 xl:col-span-8 ">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 3xl:grid-cols-2 place-content-stretch ">
                <BookingsCard
                  imgUrl={require('../../Assets/img/odds/calender.png')}
                  bookingTitle={'In-person Appointments'}
                  bookingCount={inpersoncount}
                  bookingPercentage={'4.8%'}
                  bookingFromDate={'from last week'}
                />
                <BookingsCard
                  imgUrl={require('../../Assets/img/odds/virtualbookings.png')}
                  bookingTitle={'Virtual Appointments'}
                  bookingCount={virtualcount}
                  bookingPercentage={'10.8%'}
                  bookingFromDate={'from last week'}
                />
                <MonthlyCredits
                  monthlydata={monthlydata}
                  monthlylable={monthlylable}
                  totalCreditCount={totalcreditused}
                />
                <ConsultationHistory
                  consultationhistorylable={consultationhistorylable}
                  consultationhistoryremote={consultationhistoryremote}
                  consultationhistoryinperson={consultationhistoryinperson}
                  consultationhistorysucc={consultationhistorysucc}
                  consultationhistoryremotecount={
                    consultationhistoryremotecount
                  }
                  consultationhistoryinpersoncount={
                    consultationhistoryinpersoncount
                  }
                  consultationhistorysucccount={consultationhistorysucccount}
                />
                <div className="mt-8 sm:col-span-2 3xl:col-span-4 py-4 mb-6 lg:mb-0">
                  <div className="flex justify-between items-center mb-6">
                    <p className="capitalize text-coalBlack subHeadingText ">
                      Request History
                    </p>
                    <Link to={'/requestbroadcast'}>
                      <span className="capitalize text-gray-400 smallText cursor-pointer">
                        see all
                      </span>
                    </Link>
                  </div>
                  <div className="space-y-3">{consultationBlock}</div>
                </div>
              </div>
            </div>
            <div className="col-span-4  hidden xl:block max-h-[500px] sticky top-24">
              <div className="flex justify-end mb-4">
                <select
                  className="bg-accent text-white px-4 py-2 rounded-[4px] baseText w-full"
                  value={notificationFilter}
                  onChange={handleFilterChange}
                >
                  <option
                    value="today"
                    className="text-coalBlack bg-white font-medium text-sm whitespace-nowrap"
                  >
                    Today
                  </option>
                  <option
                    value="weekly"
                    className="text-coalBlack bg-white font-medium text-sm whitespace-nowrap"
                  >
                    This Week
                  </option>
                  <option
                    value="monthly"
                    className="text-coalBlack bg-white font-medium text-sm whitespace-nowrap"
                  >
                    This Month
                  </option>
                </select>
              </div>
              <div className="p-4 pb-0 rounded-xl h-full relative bg-white">
                <SideMessageNotification
                  data={notification}
                  profileData={profileData}
                  currentdata={currentdata}
                  setcurrentdata={setcurrentdata}
                  viewFunction={handelViewCreateRequestBlockfull}
                  notificationfun={() => {
                    getNotification(profileData?.DoctorProfile?._id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Dashboard large screen end */}
      </main>

      {/* view request block  */}
      {viewRequestBlock && (
        <ViewRequest
          closeFunction={handelViewCreateRequestBlock}
          reqId={reqId}
          data={currentdata}
        
          onload={onload}
        />
      )}
      {viewRequestBlockfull && (
        <ViewDetails
          closeFunction={handelViewCreateRequestBlockfull}
          currentdata={reqId}
          profileData={profileData}
        />
      )}
    </AdminLayout>
  );
}

export default DashboardOdds;
