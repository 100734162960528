import React, { createContext, useContext,useEffect, useState } from 'react';
import { AppContext } from './../../appContext';
// import { WeavyClient, WeavyProvider, Chat, MessengerContext, MessengerProvider, ConversationList, Conversation } from '@weavy/uikit-react';
import chatIcon from '../../Icons/newChat.svg';
import newChat from '../../Icons/chat.svg';
import mashersPeople from '../../Icons/mashersPeopleIcon.svg';
import briefChat from '../../Icons/briefCaseIcon.svg';
import leftArrow from '../../Icons/leftArrowLight.svg';
// import '@weavy/uikit-react/dist/css/weavy.css';
// import styled from 'styled-components';
// import './chat.css';
import axios from 'axios';
import chat from '../../Icons/chat.svg';
import { weavyTokenApi } from '../../apis';
let fname,lname,userfname, notiCount, userid,usertoken,weavyClient,briefdetails;
const accessToken = process.env.REACT_APP_WEAVY_API_KEY;
const tsKey = process.env.REACT_APP_TYPESENSE_API_KEY;
const tsHost = process.env.REACT_APP_HOST_NAME_NEW;
const weavyUrl = process.env.REACT_APP_WEAVY_URL;
const MasherChat = ({
  appId,
  userId,
  briefId,
  ...props
}) => {
  const [hiddenChat, setHiddenChat] = useState(false);
  const [groupChat, setGroupChat] = useState(false);
  const [chatList, setChatList] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [notificationCount, setNotificationCount] = useState();
  const url = window.location.href;
  const urlArray = url.split('/');
  const chatroom = urlArray[5];
  const chatuid = urlArray[5]+'-'+urlArray[3];
  const appContext = useContext(AppContext);
  const state = appContext;
  const { loggedInUser } = state;
  fname = props.current?.firstName || loggedInUser?.data[0].firstName || '';
  lname = props.current?.lastName || loggedInUser?.data[0].lastName || '';
  userfname = fname+'-'+lname;
  userfname = userfname.replace(/\s+/g, '');
  const getWeavyToken = async (userfname) =>{
    try {
      if(userfname === undefined){
        
        console.log('user name undefined');
      }
      else{
        
        console.log(userfname);
        const values = {firstname: userfname};
        const res = await weavyTokenApi(values);
        if (res.status === 200 || 201) {
          console.log(res);
          return res.data;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  if(urlArray[3] == 'briefs'){
    let tsconfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: tsHost+'/collections/briefsv2/documents/search?q='+chatroom+'&query_by=_id',
      headers: { 
        'X-TYPESENSE-API-KEY': tsKey
      }
    };
  
    axios.request(tsconfig)
      .then((response) => {
        briefdetails  = response.data.hits[0].document.name;
        weavySetup(briefdetails);
        // console.log('Brief details'+briefdetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  else if(urlArray[3] == 'projects'){
    let tsconfig = {
      method: 'get',
      maxBodyLength: Infinity,
      // url: 'https://'+tsHost+'/collections/projectsv2/documents/search?q='+chatroom+'&query_by=_id',
      url: 'https://'+tsHost+'/collections/campaigns/documents/search?X-TYPESENSE-API-KEY=SGdIWdineRbSHFAxghr5FXFxuvfVC5kT&q=*&query_by',
      headers: { 
        'X-TYPESENSE-API-KEY': tsKey
      }
    };
  
    axios.request(tsconfig)
      .then((response) => {
        briefdetails  = response.data.hits[0].document.name;
        weavySetup(briefdetails);
        // console.log('Brief details'+briefdetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const userurl = weavyUrl+'/api/users/'+userfname;
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };
  const data = {
    uid: userfname,
    name:fname+''+lname,
    directory: briefdetails
  };

  const weavySetup = async(briefname) =>{
    usertoken = await getWeavyToken(userfname);
    axios.put(userurl, data, { headers })
      .then(response => {
        // console.log('Success:', response.data.name);
        userid = response.data.uid;
        const chaturl = weavyUrl+'/api/apps/init';

        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };

        const chatdata = {
          app: {
            uid: chatuid,
            name: briefname,
            type: 'Chat',
          },
          user: {
            uid: userfname
          },
        };

        axios.post(chaturl, chatdata, { headers })
          .then(response => {
            // console.log('Success:', response.data);
            /*const tokenurl = weavyUrl+'/api/users/'+userfname+'/tokens';
            const headers = {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            };
            axios.post(tokenurl, null, { headers })
              .then(response => {
                // console.log('Success:', response.data.access_token);
                usertoken = response.data.access_token;
              })
              .catch(error => {
                console.error('Error:', error.response.data);
              });*/
          })
          .catch(error => {
            console.error('Error:', error.response.data);
          });
      })
      .catch(error => {
        console.error('Error:', error.response.data);
      });
  };
  
  const initChat = async () =>{
    try{
      weavyClient = new WeavyClient({ 
        url: weavyUrl, 
        tokenFactory: async () => usertoken
      });
      setHiddenChat(true);
      setGroupChat(true);
    } catch(error){
      console.error('Error:', error.response.data);
    }
  };
  const closeChat = async () =>{
    setHiddenChat(false);
  };
  const openChat = async () =>{
    setGroupChat(false);
    setChatList(true);
    setIsActive(false);
  };
  const openGroup = async () =>{
    setGroupChat(true);
    setChatList(false);
    setIsActive(true);
  };
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: weavyUrl+'/api/notifications?order_by=id+desc&unread=true&count_only=true',
    headers: { 
      'Authorization': 'Bearer '+usertoken,
      'Content-Type': 'application/json',
    }
  };

  axios.request(config)
    .then(response => {
      notiCount=response.data.count;
      setNotificationCount(response.data.count);
      // console.log('response from axios request',response.data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  return (
    <>
      {/* <div className="chat-menu" onClick={initChat}><img src={chatIcon}/><div className="chatCount">{notificationCount}</div></div> */}
      <div className="App chat-main-window">
        {!hiddenChat && <div className="chat-menu" onClick={initChat}><img src={chatIcon}/><div className="chatCount">{notificationCount}</div></div>}
        {!hiddenChat && 
        <div className='chat-direction-container'>
          <span style={{padding: '40px'}}>Choose action above</span>
        </div>
        }
        {hiddenChat && (
          <>
            <div className="chat-header">
              <div className='chat-header-left'>
                <div className="backIcon" onClick={closeChat}>
                  <img src={leftArrow} />
                </div>
            
                <div className="chat-heading">Chat</div>
              </div>
              <div className="toggleChats">
                <div><img src={mashersPeople} className={isActive ? '' : 'activeChat'} onClick={openChat}/><div className={isActive ? '' : 'activeText'}>P2P</div></div>
                <div><img src={briefChat} className={isActive ? 'activeChat' : ''} onClick={openGroup} /><div className={isActive ? 'activeText' : ''}>Group</div></div>
              </div>
            </div>
            {groupChat && (
              <WeavyProvider client={weavyClient}>
                <Chat uid={chatuid} features={{ cloudFiles: false, attachments: false, mentions: true, receipts: true, typing: true }} />
              </WeavyProvider>
            )}
            {chatList && (
              <WeavyProvider client={weavyClient}>
                <MessengerProvider>
                  <div className='conversation-list-container'>
                    {/* <img src={chat}/> */}
                    <ConversationList />
                  </div>
                  <Conversation features={{ cloudFiles: false, attachments: false, mentions: true, receipts: true, typing: true }}/>
                </MessengerProvider>
              </WeavyProvider>
            )}
          </>
        )}
        
      </div>
    </>
  );
};

export default MasherChat;