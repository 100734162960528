import React from 'react';

function LoaderCard() {
  return (
    <>
      <div className="min-h-48 cardLoader rounded-2xl"></div>
    </>
  );
}

export default LoaderCard;
