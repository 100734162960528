// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Let cursor flags show on the first line */
div.ql-container {
  overflow: unset;
}

/* Remove editor focus */
div.ql-editor:focus {
  outline: none;
}

/* Hide non-visible clipboard element */
div.ql-clipboard {
  display: none !important;
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}

.ql-editor{
  outline: 0px;
}
.ql-editor:focus-visible{
  outline: 0px;
}
.ql-clipboard{
  outline: 0px;
}
.ql-clipboard:focus-visible{
  outline: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Briefs/EditBrief/globals.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;EACE,eAAe;AACjB;;AAEA,wBAAwB;AACxB;EACE,aAAa;AACf;;AAEA,uCAAuC;AACvC;EACE,wBAAwB;EACxB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":["/* Let cursor flags show on the first line */\ndiv.ql-container {\n  overflow: unset;\n}\n\n/* Remove editor focus */\ndiv.ql-editor:focus {\n  outline: none;\n}\n\n/* Hide non-visible clipboard element */\ndiv.ql-clipboard {\n  display: none !important;\n  left: -100000px;\n  height: 1px;\n  overflow-y: hidden;\n  position: absolute;\n  top: 50%;\n}\n\n.ql-editor{\n  outline: 0px;\n}\n.ql-editor:focus-visible{\n  outline: 0px;\n}\n.ql-clipboard{\n  outline: 0px;\n}\n.ql-clipboard:focus-visible{\n  outline: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
