import React, { useEffect, useState, useMemo,useContext } from 'react';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import AddButton from '../../components/common/buttons/AddButton';
import AdminLayout from '../../components/admin-layout';
import { UserContext } from '../../appContext';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseCompanyListComponent from '../../components/TypesenseList/TypesenseCompanyListComponent';
import SimpleModal from '../../components/Modals/SimpleModal';
import Createcompany from './Createcompany';
import { AppContext } from '../../appContext';
import {
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  fetchCountrycodeApi,
  getUserApi
} from "../../apis/index.js";
import { EditFormContext } from '../../appContext';

export default function CompaniesListView() {
  const [startcompany, setStartcompany] = useState(false);
  const startcompanydetails = () => {
    setStartcompany(!startcompany);
  }; 
  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;
  const [formSelects, setformSelects] = useState({
    region: "",
    industry: "",
    engagementType: "",
    category: "",
    client: "",
    loading: true,
  });
  const fetchSelectData = async () => {
    setformSelects({ loading: true });
    try {
      const selectResponse = {
        regionRes: await fetchRegionApi(),
        countryRes: await fetchCountrycodeApi(),
        industryRes: await fetchIndustryApi(),
        engagementRes: await fetchEngagementTypeApi(),
        cateRes: await fetchCategoryApi(),
        clientRes: await fetchClientApi(),
        currentUser: await getUserApi(),
      };

      setformSelects({
        region: selectResponse?.regionRes.data,
        country: selectResponse?.countryRes.data.countries,
        industry: selectResponse?.industryRes.data,
        engagementType: selectResponse?.engagementRes.data,
        category: selectResponse?.cateRes.data,
        client: selectResponse?.clientRes.data,
        currentUser: selectResponse?.currentUser.data,
        type:selectResponse?.clientRes?.data,
        loading: false,
      });
    } catch (error) {
      setformSelects({ loading: false });
    }
  };
  useEffect(() => {
    fetchSelectData();
  }, [loggedInUser]);
  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'companies',
          sort_by: 'companyName:asc',
          query_by: 'companyName, companyCountry, industry',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  const tableHeaders = [
    {
      title: 'Company'
    },
    {
      title: 'Domain'
    },
    {
      title: 'Industry'
    },
    {
      title: 'Country'
    },
    {
      title: 'Hubspot',
      display: 'flex',
      justify: 'center'
    }
  ];
  const columnWidth = () => {
    return `${100 / tableHeaders.length}%`;
  };
  

  return (
    <AdminLayout>
      <UserContext.Provider>
      <PageHeader title='Companies' buttonsArray={[
          <AddButton
            title='Create Company' 
            altText='Create Company'
            buttonType='primary'
            buttonFunction='openModal'
            onClickEvent={startcompanydetails}
          />
        ]}/>
       
        <div className='container-fluid'>
          <article>
            <main className='pt-6 d-flex flex-column'>
              <TypesenseCompanyListComponent
                searchClient={searchClient}
                headers={tableHeaders}
                columnWidth={columnWidth()}
                blockTitle='Companies'
                tablePaddingRequired={false}
              /> 
            </main>
          </article>
        </div>
        <EditFormContext.Provider
          value={{
            formSelects,
          }}
        >
           {
            startcompany && 
              <SimpleModal title='Create Company' description='All fields are mandatory' closeModal={startcompanydetails} >
                <Createcompany  closeModal={startcompanydetails} />
              
              </SimpleModal>
          }
        </EditFormContext.Provider>
       
          
      </UserContext.Provider>
    </AdminLayout>
  );
}
