// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.instagram-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}
span.influencer-err {
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
}
.handle-id {
    font-weight: 600;
    font-size: 20px;
}
.handle-username {
    font-size: 17px;
    color: #b3b1b1;
}
.flex-insight-2ndtxt {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    color: grey;
}
.flex-insight-num {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-size: 23px;
    font-weight: bold;
}
.flex-cotainer-insta {
    display: flex;
    text-align: center;
    justify-content: space-around;
    width: 91%;
    margin-top: 2%;
}
.flex-inshits {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.instagaram-nodata {
    height: 23vw;
    width: 31vw;
}`, "",{"version":3,"sources":["webpack://./src/Pages/influencer/influencer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,6BAA6B;IAC7B,UAAU;IACV,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;AACA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":["img.instagram-img {\n    height: 100px;\n    width: 100px;\n    border-radius: 50%;\n}\nspan.influencer-err {\n    text-align: center;\n    line-height: 30px;\n    font-size: 18px;\n    font-weight: 600;\n}\n.handle-id {\n    font-weight: 600;\n    font-size: 20px;\n}\n.handle-username {\n    font-size: 17px;\n    color: #b3b1b1;\n}\n.flex-insight-2ndtxt {\n    font-size: 15px;\n    text-align: center;\n    font-weight: 500;\n    color: grey;\n}\n.flex-insight-num {\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: white;\n    font-size: 23px;\n    font-weight: bold;\n}\n.flex-cotainer-insta {\n    display: flex;\n    text-align: center;\n    justify-content: space-around;\n    width: 91%;\n    margin-top: 2%;\n}\n.flex-inshits {\n    display: flex;\n    flex-direction: column;\n    gap: 2px;\n}\n.instagaram-nodata {\n    height: 23vw;\n    width: 31vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
