import React, { useEffect, useReducer } from 'react';
import AdminLayout from '../../components/admin-layout';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FaStarOfLife } from 'react-icons/fa6';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  DoctorRegistrationStepFour,
  DoctorRegistrationStepOne,
  DoctorRegistrationStepThree,
  DoctorRegistrationStepTwo,
} from '../../components/Validation/index.js';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader.js';
import { useState } from 'react';
import { BiBell } from 'react-icons/bi';
import { FiChevronLeft } from 'react-icons/fi';
import { FaAddressCard } from 'react-icons/fa';
import { LuUpload } from 'react-icons/lu';
import { PiCertificateFill } from 'react-icons/pi';
import { BsFileMedicalFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi2';
import { FaFileAlt } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa6';
import MobileModel from '../../components/Modals/MobileModel.js';
import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import {
  getProfileById,
  getSpecialization,
  profileCreate,
} from '../../apis/index.js';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../../components/UserProfile/UserProfile.js';
import RegisterOne from './RegisterOne.js';
import RegisterTwo from './RegisterTwo.js';
import RegisterThree from './RegisterThree.js';
import RegisterFour from './RegisterFour.js';
function RegistrationForm({ prevScreen, nextScreen }) {
  const [loader, setLoader] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [specialization, setSpecialization] = useState([]);

  const onProfileCreate = async (payload) => {
    try {
      setLoader(true);
      const res = await profileCreate(payload);
      if (res.status === 200) {
        localStorage.removeItem('tempToken');
      }
      setLoader(false);
      setFormSubmit(!formSubmit);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleNext = () => {
    // formik.validateForm().then((errors) => {
    //   if (Object.keys(errors).length === 0) {
    //     setStep((prevStep) => prevStep + 1);
    //     setFormValues(formik.values);
    //   } else {
    //     const touched = {};
    //     Object.keys(errors).forEach((fieldName) => {
    //       touched[fieldName] = true;
    //     });
    //     formik.setTouched(touched);
    //   }
    // });
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      prevScreen();
    }
    // formik.setValues(formValues);
  };

  let stepCount;
  let stepBar;
  if (step === 1) {
    stepBar = 'w-1/4';
    stepCount = '1/4';
  } else if (step === 2) {
    stepBar = 'w-1/2';
    stepCount = '2/4';
  } else if (step === 3) {
    stepBar = 'w-3/4';
    stepCount = '3/4';
  } else if (step === 4) {
    stepBar = 'w-full';
    stepCount = '4/4';
  }

  // const formik = useFormik({
  //   initialValues: {
  //     docName: '',
  //     birthDate: '',
  //     gender: '',
  //     countryCode: '+91',
  //     phoneNo: '',
  //     email: '',
  //     specialization: '',
  //     regNumber: '',
  //     smCouncil: '',
  //     dateOfReg: '',
  //     practiceHospital: '',
  //     HospitalNumber: '',
  //     MedicalLicense: undefined,
  //     DegreeCertificate: undefined,
  //     refContact: '',
  //     refPhoneNo: '',
  //   },
  //   validationSchema:
  //     step === 1 ? (
  //       DoctorRegistrationStepOne
  //     ) : step === 2 ? (
  //       DoctorRegistrationStepTwo
  //     ) : step === 3 ? (
  //       DoctorRegistrationStepThree
  //     ) : step === 4 ? (
  //       DoctorRegistrationStepFour
  //     ) : (
  //       <></>
  //     ),
  //   onSubmit: async (values) => {
  //     setLoader(true);
  //     console.log(values);
  //     const {
  //       birthDate,
  //       gender,
  //       countryCode,
  //       phoneNo,
  //       specialization,
  //       regNumber,
  //       smCouncil,
  //       dateOfReg,
  //       practiceHospital,
  //       HospitalNumber,
  //       refPhoneNo,
  //       refContact,
  //     } = values;
  //     const payloadFormat = {
  //       userId: localStorage.getItem('userId'),
  //       isActive: true,
  //       status: 'active',
  //       birthDate: birthDate,
  //       gender: gender,
  //       countryCode: countryCode,
  //       phoneNo: phoneNo,
  //       specialization: specialization,
  //       regNumber: regNumber,
  //       smCouncil: smCouncil,
  //       dateOfReg: dateOfReg,
  //       practiceHospital: practiceHospital,
  //       hospitalNumber: HospitalNumber,

  //       workExperience: {
  //         noYear: '',
  //         hospitalName: '',
  //       },
  //       documents: [],
  //       reference: {
  //         contactNo: refPhoneNo,
  //         contactName: refContact,
  //       },
  //       coverPic: 'https://example.com/path/to/cover.jpg',
  //     };
  //     onProfileCreate(payloadFormat);
  //   },
  // });
  const formik1 = useFormik({
    initialValues: {
      docName: '',
      birthDate: '',
      gender: '',
      countryCode: '+91',
      phoneNo: '',
      email: '',
    },
    validationSchema: DoctorRegistrationStepOne,

    onSubmit: async (values) => {
      console.log(values);
      setStep(2);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      specialization: '',
      regNumber: '',
      smCouncil: '',
      dateOfReg: '',
      chosenOption: '',
    },
    validationSchema: DoctorRegistrationStepTwo,

    onSubmit: async (values) => {
      console.log(values);
      setStep(3);
    },
  });
  const formik3 = useFormik({
    initialValues: {
      practiceHospital: '',
      HospitalNumber: '',
      HospitalMobileNumber: '',
    },
    validationSchema: DoctorRegistrationStepThree,

    onSubmit: async (values) => {
      console.log(values);
      setStep(4);
    },
  });
  const formik4 = useFormik({
    initialValues: {
      MedicalLicense: undefined,
      DegreeCertificate: undefined,
      refContact: '',
      refPhoneNo: '',
      profile: '',
    },
    validationSchema: DoctorRegistrationStepFour,

    onSubmit: async (values) => {
      setLoader(true);
      console.log(values);
      const {
        birthDate,
        gender,
        countryCode,
        phoneNo,
        docName,
      } = formik1.values;
      const {
        specialization,
        regNumber,
        smCouncil,
        dateOfReg,
      } = formik2.values;
      const {
        practiceHospital,
        HospitalNumber,
        HospitalMobileNumber,
      } = formik3.values;
      const {
        refPhoneNo,
        refContact,
        profile,
        MedicalLicense,
        DegreeCertificate,
      } = formik4.values;
      const payloadFormat = {
        userId: localStorage.getItem('userId'),
        DoctorName: docName,
        isActive: true,
        status: 'active',
        birthDate: birthDate,
        gender: gender,
        countryCode: countryCode,
        phoneNo: phoneNo,
        specialization: specialization,
        regNumber: regNumber,
        smCouncil: smCouncil,
        dateOfReg: dateOfReg,
        practiceHospital: practiceHospital,
        hospitalNumber: HospitalNumber,
        hospitalMobileNumber: HospitalMobileNumber,
        workExperience: {
          noYear: '',
          hospitalName: '',
        },
        documents: [
          {
            type: 'Medical License',
            id: '',
            fileName: '',
            filePath: MedicalLicense,
            fileSize: '',
          },
          {
            type: 'Degree Certificate',
            id: '',
            fileName: '',
            filePath: DegreeCertificate,
            fileSize: '',
          },
        ],
        reference: {
          contactNo: refPhoneNo,
          contactName: refContact,
        },
        coverPic: profile,
      };


      onProfileCreate(payloadFormat);
    },
  });
  // const { handleSubmit, handleChange, values, touched, errors } = formik;

  const modleBody = (
    <>
      <div className="flex flex-col items-center gap-4">
        <div className="w-40 h-40 rounded-full bg-blue-50 flex justify-center items-center">
          <FaThumbsUp className="text-6xl text-accent" />
        </div>
        <div>
          <h1 className="text-3xl font-medium text-coalBlack text-center mb-4">
            Thanks
          </h1>
          <p className="text-gray-400 max-w-xs mx-auto text-sm font-normal text-center">
            Your details has been submitted successfully for review{' '}
          </p>
        </div>
      </div>
    </>
  );
  const modelFooter = (
    <>
      <div className="mt-12">
        <button
          className="btn btn_primary_black text-white w-full rounded-xl"
          type="button"
          onClick={() => nextScreen()}
        >
          {!loader ? (
            'Done'
          ) : (
            <div className="text-xs">
              <EditProfileLoader />
            </div>
          )}
        </button>
      </div>
    </>
  );
  const GetSpecialization = async () => {
    try {
      const res = await getSpecialization(localStorage.getItem('tempToken'));
      if (res.status === 200) {
        console.log(res.data.result);
        setSpecialization(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetSpecialization();
  }, []);
  return (
    <section className="lg:w-1/2 relative lg:py-10 ">
      <div className="p-4  mx-auto scrollbar md:max-w-[400px]  lg:max-h-[96vh] lg:overflow-y-auto">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-1">
            <FiChevronLeft
              className="text-2xl font-semibold text-coalBlack cursor-pointer min-w-10 min-h-10 rounded-full hover:bg-slate-50 transition-colors duration-100 ease-in-out md:text-base p-2"
              onClick={handlePrevious}
            />
            <p className="capitalize text-2xl font-medium text-coalBlack">
              Doctor Registration
            </p>
          </div>
          <div className="bg-white p-1 rounded-xl border border-gray-300">
            <IoIosHelpCircleOutline className="text-2xl text-primary_v2" />
          </div>
        </div>

        <div className="stepsBlock mt-4 p-2 rounded-xl flex items-center gap-2 shadow-sm max-w-md md:shadow-none">
          <p className="inline-block text-sm font-semibold text-black whitespace-nowrap">
            {stepCount}
          </p>
          <div className="w-full h-2 bg-accent/[22%] rounded-full relative max-w-md">
            <div
              className={`${stepBar} h-2 rounded-full bg-accent absolute top-0 left-0`}
            ></div>
          </div>
        </div>

        <div className="mt-4 flex gap-2 items-center">
          <FaStarOfLife className=" text-accent " />
          <p className="text-sm text-gray-400 inline-block">
            {step === 3
              ? 'Fields in this section are optional'
              : 'All fields are mandatory in this section'}
          </p>
        </div>

        <div className="PersonalDetails  mt-6 mb-10 ">
          {step === 1 && <RegisterOne formik={formik1} loader={loader} />}
          {step === 2 && (
            <RegisterTwo
              formik={formik2}
              loader={loader}
              specialization={specialization}
            />
          )}
          {step === 3 && <RegisterThree formik={formik3} loader={loader} />}
          {step === 4 && <RegisterFour formik={formik4} loader={loader} />}
        </div>
        {formSubmit && (
          <>
            <div className="md:hidden block">
              <MobileModel
                MobileModelBody={modleBody}
                MobileModelFooter={modelFooter}
              />
            </div>
            <div className=" absolute top-0 left-0 h-full w-full bg-white hidden md:flex">
              <div className="flex flex-col m-auto">
                {modleBody}
                {modelFooter}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default RegistrationForm;
