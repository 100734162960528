import { useFormik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { loginApi } from '../../apis/index.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import { InputErrorMessage } from '../../components/errorMessages/index.js';
import { SignInValidation } from '../../components/Validation';
import LogoImage from '../../Assets/img/logos/mash_yellow.png';
import Cover from '../../Assets/img/covers/Login.jpg';
import { assignBucketUrl } from '../../utils/helperFunctions.js';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';

export default function SignIn() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
   
  const handleChange = (event) => {
    event.preventDefault();
    formik.handleChange(event);
  };
  const formik = useFormik({
    initialValues: { email: '', keepLoggedIn: false },
    validationSchema: SignInValidation,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setLoader(true);
        const res = await loginApi({ email });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/otp', { state: { email: formik.values.email, keepLoggedIn: formik.values.keepLoggedIn } });
        }
      } catch (error) {
        setLoader(false);
        const message = error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      }
    },
  });
  return (
    <>
      <div className='min-h-screen bg-surface-secondary d-flex flex-column justify-content-center'>
        <div className='row mx-0'>
          <div className='col-lg-3 p-6 h-screen overflow-y-hidden bg-black'>
            <img
              alt=''
              src={assignBucketUrl(LogoImage)}
              onError={(event) => {
                event.target.src = ProfileDefault;
                event.onerror = null;
              }}
              width='150'
            />
            <div className='mt-5'>
              <h1 className='ls-tight display-5 text-white mb-5 font-bolder' style={{ fontWeight: '500' }}>
                Welcome Back
              </h1>
              <p className='text-white'>The weather in Tulum right now is {'24'}°C🌤️. Food for thought. </p>
              <div className='col-lg-12 px-0 mt-8'>
                <form onSubmit={formik.handleSubmit} className="sign-in-up">
                  <h2 className='font-bolder h-4 text-white mb-8'>Login</h2>
                  <div className='mb-0'>
                    <label className='form-label text-white' htmlFor='email'>
                      Email address
                    </label>
                    <input
                      type='text'
                      className={Boolean(formik.touched.email) && Boolean(formik.errors.email) ? 'form-control border-danger transparent-input-border' : 'form-control transparent-input-border'}
                      id='email'
                      placeholder='Your email address'
                      value={formik.values.email}
                      onChange={handleChange}
                    />
                    <InputErrorMessage error={formik.touched.email && formik.errors.email} marginBottom={-5} />
                  </div>
                  <div className='mb-4'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='keepLoggedIn'
                        id='keepLoggedIn'
                        onChange={(e) => formik.setFieldValue('keepLoggedIn', e.target.checked ? true : false)}
                        value={formik.values.keepLoggedIn}
                      />
                      <label className='form-check-label text-white' htmlFor='check_example'>
                        Keep me logged in
                      </label>
                    </div>
                  </div>
                  <div>
                    <button className='btn bg-primary w-full text-dark font-bold noYellowOnHover' type='submit'>
                      {!loader ? (
                        'Send Verification Code'
                      ) : (
                        <div className='text-xs'>
                          <EditProfileLoader />
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className='col-lg-9 border-left-lg min-h-screen position-relative'
            style={{
              backgroundImage: `url(${assignBucketUrl(Cover)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'Cover',
              backgroundPositionY: 'center',
            }}>
            <div className='py-lg-16'>
              <div className='row'>
                <div className='col-lg-12 top-div px-lg-10 text-end'>
                  <span className='ls-tight mb-3'>
                    Illustration by
                    <br />
                    Masher Ben Thomson
                  </span>
                </div>
                <div className='col-lg-12 bottom-div px-lg-10'>
                  <div className='text-center mb-6 '>
                    <h2 className='ls-tight display-4 mb-3 text-end d-none d-md-block font-bolder' style={{ lineHeight: '1' }}>
                      Our office <br />
                      is only 148 billion <br /> square metres.
                    </h2>
                    <h2 className='ls-tight display-4 mb-3 text-end d-block d-md-none'>Our office is only 148 billion square metres.</h2>
                    <p className='text-end text-white d-none d-md-block' style={{ lineHeight: '1.3' }}>
                      You're basically standing at our reception <br /> only this time you're in your ugg boots.
                    </p>
                    <p className='text-end  text-white d-block d-md-none'>
                      You're basically standing at our reception only this time you're in your ugg boots.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
