import React, { useState, useEffect } from 'react';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import { getTransactionHistoryApi } from '../../apis';
import Moment from 'react-moment';
const tableHeaders = [
  {
    name: 'Hospital Name',
    specialization: 'Specialization',
    type: <p className="text-center">Type</p>,
    CompletedDate: 'Completed Date',
    Payment: 'Payment',
    Status: 'Payment Status',
  },
];
function TranstationHistory({ doctorId = null }) {
  const [data, setdata] = useState(null);
  const [loader, setLoader] = useState(false);
  const getItem = async () => {
    try {
      setLoader(true);
      const res = await getTransactionHistoryApi(doctorId);
      if (res.status === 200) {
        setdata(res.data.TransactionHistory);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getItem();
  }, [doctorId]);
  return (
    <>
      <h1 className="text-coalBlack subHeadingText ps-2.5 pt-2.5 mb-2">
        Consultation History
      </h1>
      <PaginatedTable
        items={data?.map((data) => ({
          name: (
            <div>
              <p className="baseText capitalize line-clamp-1">
                {data?.hospitalName[0]}
              </p>
            </div>
          ),
          specialization: (
            <p className="baseText capitalize line-clamp-1">
              {data?.Specalaization[0]}
            </p>
          ),
          Type: (
            <p className="baseText capitalize line-clamp-1">
              {data?.consultationType[0]}
            </p>
          ),
          CompletedDate: (
            <p className="baseText capitalize line-clamp-1 text-center">
              <Moment
                format="DD/MM/YYYY"
                date={data?.ConsultationCompletedDate[0]}
              />
            </p>
          ),
          Payment: (
            <p className="baseText capitalize line-clamp-1 text-center">
              ₹ {data?.DoctorFee}
            </p>
          ),
          Status: (
            <p className="baseText capitalize line-clamp-1">
              {data?.Paymentstatus == 'paid' ? 'Success' : 'Pending'}
            </p>
          ),
        }))}
        itemsPerPage={10}
        tableHeaders={tableHeaders}
      />
    </>
  );
}

export default TranstationHistory;
