import React, { useState } from 'react';

function Toggle({ onclick, status }) {

  return (
    <div
      className={`w-11 h-6  rounded-full relative ${
        status ? 'bg-green-500' : 'bg-gray-200'
      }`}
      onClick={onclick}
    >
      <span
        className={`size-4 rounded-full bg-white absolute top-1/2  -translate-y-1/2 transition-all duration-100 ease-linear ${
          status ? 'left-6' : 'left-1'
        }`}
      ></span>
    </div>
  );
}

export default Toggle;
