import React, { useState } from 'react';
import Avatar from '../../../components/Avatar/Avatar';
import ProfilePreview from '../../MasherProfileView';

function RoleCardProject({deliverable}) {
  const[currentid,setcurrentid]=useState('');
  return (
    <div>
      {deliverable.role.map((role, i) => {
        return (
          <div className='role-card'>
            {
              role?.Masher.map((masher, index) => {
                return (
                  
                  <div key={index} className='project-role-card-row'> 

                    <div className='project-role-card-masher-details-left'>    
                      <div style={{width: '420px'}}>
                        <h5 style={{marginBottom: '5px'}}>{ deliverable.deliverableName }</h5>
                        <p style={{textWrap: 'pretty'}}>{deliverable.role ? role?.roleName : ''}</p>
                      </div>
                      <a 
                        data-bs-toggle="modal"
                        data-bs-target="#profilePreview"
                        id="profilePreview_open"
                        className='flex items-center'
                        onClick={()=>{
                          setcurrentid(masher);
                        }}
                      >
                        <Avatar id={masher.Id} name={masher.Name} url={masher.Photo} borderColor='white'/>
                        <p style={{marginLeft: '10px'}}>{masher.Name}</p>
                      </a>
                    </div>


                  </div>
                  
                );
              }) 
            }
          </div>
        );
      }) }
      <ProfilePreview data={currentid}/>
    </div>
      
  );
}

export default RoleCardProject;