import React from 'react';

const MandatoryLabel = ({title}) => {
  return (
    <p className="baseText text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
      {title}
    </p>
  );
};

export default MandatoryLabel;
