// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-container{
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
}
.avatar-no-url{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}
.avatar-url {
  width: 100%;
  height: 100%;

}
.avatar-url > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Avatar/avatar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;;AAEb;AACA;EACE,WAAW;EACX,YAAY;;AAEd;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".avatar-container{\n  width: 40px;\n  height: 40px;\n  min-width: 40px;\n  border-radius: 50%;\n  display: flex;\n}\n.avatar-no-url{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  \n}\n.avatar-url {\n  width: 100%;\n  height: 100%;\n\n}\n.avatar-url > img {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
