export function formatDate(dateString) {
    const date = new Date(dateString);

    // Helper function to get the date suffix
    function getSuffix(day) {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    // Array of month names
    const months = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const suffix = getSuffix(day);

    return `${day}${suffix} ${month} ${year}`;
}