import React, { useContext, useEffect, useState } from 'react';
import { getOddsWeavyTokenApi } from '../../../apis';
import {
  useWeavy,
  WyChat,
  WyNotificationToasts,
  Weavy,
  WyNotifications,
} from '@weavy/uikit-react';
import { AppContext } from '../../../appContext';
import ViewDetails from '../../ConsultationCard/ViewDetails';
import axios from 'axios';

function WeavyToastNotification() {
  const { userDetails, setChatCount } = useContext(AppContext);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);

  const handleViewRequestBlock = (data) => {
    setViewRequestBlock(!ViewRequestBlock);
    setCurrentRequest(data);
  };
  // notification event listeners
  useEffect(() => {
    const handleNotificationClick = (e) => {
      e.preventDefault();
      const data = e.detail;
      handleViewRequestBlock(data.app.uid);
    };
    const handleTostNotification = async (e) => {
      e.preventDefault();

      try {
        const hospitalDetails = {
          userId: userDetails?.DoctorProfile?._id,
          username: `Dr.${userDetails?.DoctorProfile?.DoctorName}`,
        };
        const isHospitalToken = await getWeavyToken(hospitalDetails);
        getNotificationCount(isHospitalToken);
      } catch (error) {
        console.log(error);
      }
    };
    document.addEventListener('wy:link', handleNotificationClick);
    document.addEventListener('wy:notifications', handleTostNotification);
    return () => {
      document.removeEventListener('wy:notifications', handleTostNotification);
      document.removeEventListener('wy:link', handleNotificationClick);
    };
  }, []);
  // subscribe the notification
  const getWeavyToken = async (user) => {
    try {
      const res = await getOddsWeavyTokenApi(user);
      if (res.status === 200 || res.status === 201) {
        return res.data.token;
      }
    } catch (err) {
      console.error('Error:', err);
      // throw err;
    }
  };
  useEffect(() => {
    const initializeChat = async () => {
      try {
        const hospitalDetails = {
          userId: userDetails?.DoctorProfile?._id,
          username: `Dr.${userDetails?.DoctorProfile?.DoctorName}`,
        };
        const isHospitalToken = await getWeavyToken(hospitalDetails);

        // Initialize Weavy instance
        const weavy = new Weavy();
        weavy.notificationEvents = true;
        weavy.url = process.env.REACT_APP_WEAVY_URL;
        weavy.tokenFactory = async () => isHospitalToken;
        getNotificationCount(isHospitalToken);
        // Clean up Weavy instance on unmount
        return () => {
          console.log('Cleaning up Weavy instance');
          weavy.destroy();
        };
      } catch (err) {
        console.error('Error initializing chat:', err);
      }
    };

    initializeChat();
  }, [userDetails]);
  const getNotificationCount = async (token) => {
    try {
      const url = `${process.env.REACT_APP_WEAVY_URL}/api/notifications?type=&countOnly=true&unread=true`;
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setChatCount(res?.data?.count);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <WyNotificationToasts duration={2000}></WyNotificationToasts>
      {ViewRequestBlock && (
        <ViewDetails
          currentdata={currentRequest}
          closeFunction={handleViewRequestBlock}
        />
      )}
    </div>
  );
}

export default WeavyToastNotification;
