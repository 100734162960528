import React, { useContext, useEffect, useState } from 'react';
import ChooseAccount from './ChooseAccount';
import GetBankDetails from './GetBankDetails';
import EditBankDetails from './EditBankDetails';
import GetPayoutAmount from './GetPayoutAmount';
import { getWithdrawHistory } from '../../apis';
import { AppContext } from '../../appContext';

function VerifyBankIndex({ balance }) {
  const [step, setStep] = useState(1);
  const [Amountrequested, setAmountrequested] = useState(0);
  const { userDetails } = useContext(AppContext);
  const [withdrawRequest, setWithdrawRequest] = useState(null);
  const toGetWithdrawHistory = async (id) => {
    try {
      const res = await getWithdrawHistory(id);
      if (res.status === 200) {
        console.log(res?.data?.WithdrawHistory);
        const array = res?.data?.WithdrawHistory?.filter((data) => {
          if (data?.status !== 'Paid') {
            return data;
          }
        });
        setWithdrawRequest(array);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(withdrawRequest);

  useEffect(() => {
    toGetWithdrawHistory(userDetails?.DoctorProfile?._id);
  }, [userDetails]);

  function recentRequestAmountCalculate() {
    let totalAmt = 0;
    withdrawRequest?.map((data) => {
      if (data?.status === 'Requested') {
        totalAmt = data.withdrawAmt + totalAmt;
      }
    });
    return totalAmt;
  }

  return (
    <div className='md:pb-10 lg:pb-0'>
      {step === 1 ? (
        <GetPayoutAmount
          setStep={setStep}
          balance={balance}
          setAmountrequested={setAmountrequested}
          recentRequestAmount={recentRequestAmountCalculate}
        />
      ) : step === 2 ? (
        <ChooseAccount setStep={setStep} Amountrequested={Amountrequested} />
      ) : step === 3 ? (
        <GetBankDetails setStep={setStep} />
      ) : step === 4 ? (
        <EditBankDetails setStep={setStep} />
      ) : null}
    </div>
  );
}

export default VerifyBankIndex;
