import React, { useContext, useEffect, useRef, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserProfile from '../../components/UserProfile/UserProfile';
import { FiLogOut } from 'react-icons/fi';
import { GoArrowUpRight } from 'react-icons/go';
import SideMessageNotification from './SideMessageNotification';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { Link } from 'react-router-dom';
import ViewRequest from '../../components/ConsultationCard/Viewrequest';
import { getProfileById, getRecentRequest } from '../../apis';
import LoaderCard from '../../components/ConsultationCard/LoaderCard';
import { AppContext } from '../../appContext';
import ConsultationHistory from './ConsultationHistory';
import dayjs from 'dayjs';
function MonthlyCredits({ monthlydata, monthlylable, totalCreditCount }) {
  const chartRef = useRef(null);
  function createDiagonalPattern(color = 'black') {
    let shape = document.createElement('canvas');
    shape.width = 10;
    shape.height = 10;
    let c = shape.getContext('2d');
    c.strokeStyle = color;
    c.beginPath();
    c.moveTo(2, 0);
    c.lineTo(10, 8);
    c.stroke();
    c.beginPath();
    c.moveTo(0, 8);
    c.lineTo(2, 10);
    c.stroke();
    return c.createPattern(shape, 'repeat');
  }

  useEffect(() => {
    console.log(monthlydata, monthlylable);
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        // labels: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
        labels: monthlylable,
        datasets: [
          {
            label: 'Monthly Earnings',
            data: monthlydata,
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.4,
            // backgroundColor: createDiagonalPattern('skyblue'),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'skyblue',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            grid: {
              drawBorder: false,
              drawOnChartArea: false, // Remove vertical grid lines
            },
            title: {
              display: true,
              text: 'Amount',
            },
            min: 0,
            max:  Math.max(...monthlydata)+100,
          },
          x:{
            grid: {
              drawBorder: false,
              drawOnChartArea: false, // Remove vertical grid lines
            },
          }
          
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [monthlydata]);
  const month= dayjs(new Date()).format('MMMM');

  return (
    <>
      <div className="bg-white p-4 rounded-3xl shadow-blue_dropshadow">
        <div className="flex flex-wrap justify-between gap-1 ">
          <div className="space-y-1">
            <p className="headingText capitalize text-coalBlack ">
              Monthly earnings
            </p>
            <p className="headingText text-coalBlack flex items-center flex-wrap">
              <span className="subHeadingText">{totalCreditCount}</span>
              <span className="ms-2 text-navLink baseText">{month}</span>
            </p>
          </div>
          {/* <div className="shadow-md rounded-lg p-2 font-medium text-sm text-coalBlack flex items-center">
                        50,856,10{' '}
            <span className="ms-3 bg-green-200 text-green-600 rounded-lg px-2 py-1">
                            -7.32%{' '}
            </span>
          </div> */}
        </div>
        <div className="min-h-[230px] md:min-h-[275px] my-2">
          <canvas ref={chartRef} />
        </div>
      </div>
    </>
  );
}

export default MonthlyCredits;
